import { Component, HostListener } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { PopupComponent } from '../popup/popup.component';
import { colorObj } from 'src/shared/color-object';
import { Router } from '@angular/router';
import { ContactUsComponent } from '../contact-us/contact-us.component';

@Component({
  selector: 'app-partner-program',
  templateUrl: './partner-program.component.html',
  styleUrls: ['./partner-program.component.css']
})
export class PartnerProgramComponent {
  mobile: boolean;
  disabled: boolean = false;
  contactUsForm: FormGroup;
  emailPattern = environment.EMAIL_PATTERN;
  email = new FormControl('', Validators.pattern(environment.EMAIL_PATTERN));
  contactPopupSetTimeOut;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.contactUsForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(200)]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      tnc: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.checkScreenWidth();
    let popupScreen = window.sessionStorage.getItem('partnerProgramPopup');
    if (!popupScreen) {
      this.showContactPopup();
    }
  }

  ngOnDestroy() {
    clearTimeout(this.contactPopupSetTimeOut);
  }

  showContactPopup() {
    this.contactPopupSetTimeOut = setTimeout(() => {
      let popupScreen = window.sessionStorage.getItem('partnerProgramPopup');
      if (!popupScreen) {
        window.sessionStorage.setItem('partnerProgramPopup', 'true')
        const dialogRef = this.dialog.open(ContactUsComponent, {
          width: '80%',
          height: '81vh',
          position: { top: '14vh' },
          disableClose: true,
          autoFocus: false
        });
      }
    }, 5000);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  connect() {
    this.disabled = true;
    let obj = {
      "EMAIL": this.email.value
    }
    this.hotelService.connect(obj).subscribe({
      next: (data) => {
        this.disabled = false;
        this.email.setValue('');
        if (!!data?.object?.ID) {
          const dialogRef = this.dialog.open(PopupComponent, {
            width: '46%',
            data: { name: 'subscribe', message: 'We have received your email. Stay tuned!' }
          });
        } else {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        }
      },
      error: (e) => {
        this.disabled = false;
        this.email.setValue('');
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  submitContactForm() {
    this.disabled = true;
    let obj = {
      "NAME": this.contactUsForm.value.name,
      "EMAIL": this.contactUsForm.value.email,
      "MESSAGE": this.contactUsForm.value.message,
      "TNC": true
    }
    this.hotelService.contactUs(obj).subscribe({
      next: (data) => {
        this.disabled = false;
        if (!!data?.object?.ID) {
          const dialogRef = this.dialog.open(PopupComponent, {
            width: '50%',
            data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' }
          });
        } else {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        }
        this.contactUsForm.reset();
      },
      error: (e) => {
        this.disabled = false;
        this.contactUsForm.reset();
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  selectContact() {
    const contactFormSection = document.getElementById('contactFormSection');
    if (contactFormSection) {
      contactFormSection.scrollIntoView({ behavior: 'smooth' });
    }
  }

}