<app-header></app-header>
<div fxLayout="column" class="blogContainer width100">
    <div fxLayout="row">
        <div class="back-arrow" (click)="backButton()">
            <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
        </div>
        <div class="blog-header">
            HICSA 2024: A Glimpse into the Hospitality Landscape of Tomorrow
        </div>
    </div>
    <mat-divider class="material-devider"></mat-divider>
    <div fxLayout="row" class="sub-content" fxLayoutAlign="space-evenly">
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">Janhavi Srivastava</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <span class="blog-desc">25 April 2024</span>
        </div>
        <div class="points">
            <iconify-icon icon="material-symbols:circle" class="bullets"></iconify-icon>
            <iconify-icon icon="material-symbols-light:share-outline" class="share-icon"></iconify-icon>
            <a class="blog-desc" style="cursor: pointer;" (click)="viewLinkedIn()">LinkedIn</a>
        </div>
    </div>
</div>
<div class="infoContainer width100" fxLayout="column" fxLayoutAlign=" center">
    <div class="Hicsa-prgm-container" *ngIf="!mobile">
        <img src="assets/images/Hicsa-prgm.png" class="prgm-image">
    </div>
    <div class="Hicsa-prgm-container" *ngIf="mobile">
        <img src="assets/mobile-images/Hicsa-prgm 2024.png">
    </div>
    <div class="sticky-overview" fxFlex="35" [ngClass]="{ 'sticky-overview-active': showStickyOverview }">
        <div class="overview-header">Overview</div>
        <ul class="overview-list">
            <li><span (click)="hicsaIntroduction()" [class.active]="activeSection === 'hicsa-2024'">GoFryday at
                    HICSA 2024</span>
            </li>
            <li><span (click)="viewCorrelation()" [class.active]="activeSection === 'correlationcontent'">Perfect
                    Correlation
                    Highlighted</span></li>
            <li><span (click)="viewHospitalityLandscape()"
                    [class.active]="activeSection === 'hospitalityLandscapesection'">Post-Pandemic
                    Hospitality
                    Boom</span></li>
            <li><span (click)="viewInvestmentcontent()"
                    [class.active]="activeSection === 'Investmentcontent'">Hospitality's Tech
                    Investment</span></li>
            <li><span (click)="viewGuestExperience()"
                    [class.active]="activeSection === 'guestExperiencecontent'">Elevating Guest
                    Experiences</span></li>
            <li><span (click)="viewCloud()" [class.active]="activeSection === 'cloudsection'">Hospitality's
                    Cloud-First
                    Approach</span></li>
            <li><span (click)="viewRevenue()" [class.active]="activeSection === 'revenuesection'">Expanding
                    Revenue Horizons</span></li>
            <li><span (click)="viewSustainability()" [class.active]="activeSection === 'Sustainabilitysection'">Green
                    Hospitality Sustainability</span></li>
            <li><span (click)="viewConclusion()" [class.active]="activeSection === 'conclusionsection'">Conclusion:
                    GoFryday Tech
                    Partner</span></li>
        </ul>
    </div>
    <div class="right-side-content" fxLayout="column">
        <div id="hicsa-2024" fxLayout="column" class="info-content" fxLayoutGap="2%" fxFlex="65%">
            <div class="container-head">GoFryday Drives Industry Engagement at HICSA 2024</div>
            <div class="info-text">The recent HICSA (Hotel Investment Conference - South Asia) 2024
                conference in Bengaluru was a vibrant hub for hospitality leaders and innovators. We at GoFryday
                got to
                chat with tons of hospitality folks, and it was inspiring. We learned a lot about what hotels
                are
                struggling with these days, especially keeping up with all the new tech stuff. That's where
                GoFryday comes in –
                our system is like a bridge between old-school hotel ways and all the cool new tech that can
                make things
                run smoother.
            </div>
        </div>
        <div id="correlationcontent" fxLayout="row" class="Correlation">
            <div fxLayout="column" fxLayoutGap="5%" class="correlationcontent">
                <div class="container-head">HICSA and GoFryDay: A Perfect Correlation</div>
                <div *ngIf="mobile" style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/Hicsa-prgm-img1.png">
                </div>
                <div class="info-text">
                    HICSA is a premier forum for discussing trends and advancements in the South Asian
                    hospitality
                    market. This year's focus on future of hospitality resonated deeply with GoFryDay’s
                    mission.
                    Our
                    technology platform empowers hotels to not only optimize operations but also explore new
                    avenues
                    of
                    revenue in this dynamic market.
                </div>
            </div>
            <div *ngIf="!mobile">
                <img src="assets/images/Hicsa-prgm-img1.png">
            </div>
        </div>
        <div id="hospitalityLandscapesection" class="circle">
            <div class="landscape-content" fxLayoutAlign=" center">
                <iconify-icon icon="ri:double-quotes-l" class="open-quote" *ngIf="!mobile"></iconify-icon>
                <div fxLayout="column" fxLayoutGap="5%">
                    <div class="container-header">The Hospitality Landscape: A Post-Pandemic Boom</div>
                    <div class="info-texts">
                        The hospitality industry has witnessed a remarkable resurgence post-pandemic. Travel
                        restrictions
                        have eased, and people are eager to explore new destinations. This surge in demand
                        presents
                        both
                        opportunities and challenges for hoteliers. GoFryDay’s solutions are designed to
                        address
                        these
                        challenges and empower hotels to capitalize on the current boom, while also
                        exploring
                        new
                        revenue
                        streams.
                    </div>
                </div>
                <iconify-icon icon="ri:double-quotes-r" class="close-quote" *ngIf="!mobile"></iconify-icon>
            </div>
        </div>
        <div class="demo-section">
            <div class="book-contents" fxLayout="column" fxLayoutGap="2%">
                <div class="demo-text">
                    Delight your guests with AI-powered experiences
                </div>
                <div>
                    <button class="book-btn"
                        onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                        Book a Demo
                    </button>
                </div>
            </div>
        </div>
        <div id="Investmentcontent" fxLayout="column" class="technology-content" fxLayoutGap="2%">
            <div class="technology-header">Technology's Transformative Role: The Next Big Investment in
                Hospitality
            </div>
            <div class="technology-text">Technology is no longer an afterthought; it's the driving force
                behind
                hospitality's
                revolution. Gone are the days of manual processes. From managing guest experiences to
                optimizing
                operations, tech solutions are streamlining everything. GoFryDay is at the forefront of this
                transformation. We offer a comprehensive suite of tools that not only enhance guest
                satisfaction
                and
                drive revenue growth, but also address the specific pain points of hoteliers.
                Think of tech as the magic ingredient hotels need these days. We met with General Managers
                tired
                of
                manual tasks and communication silos within their teams. GoFryDay automates tedious work,
                fosters
                seamless communication, and empowers managers to focus on strategic initiatives.
            </div>
        </div>
        <div id="guestExperiencecontent" class="square">
            <div class="experience-content" fxLayoutAlign=" center">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div class="container-header">Taking guest experiences to next level</div>
                    <div class="info-texts">
                        The hospitality industry is experiencing a wave of innovation, with a focus on
                        designing
                        exceptional guest experiences. Gone are the days of generic stays! Now it's all
                        about
                        personalization (think pre-set room temperatures!), tech convenience (mobile
                        check-in!),
                        and
                        a
                        focus on wellbeing (in-room massages anyone?). Unique experiences and sustainable
                        practices
                        are
                        also trending.
                        Here at GoFryday, we're passionate about helping hotels achieve this. We recently
                        discussed
                        how
                        our platform can tailor experiences to each guest, anticipate their needs, and
                        create
                        lasting
                        memories. One hotelier even called our guest feedback tool a game-changer!
                    </div>
                </div>
            </div>
        </div>
        <div id="cloudsection" fxLayout="row" class="cloud-content">
            <div fxLayout="column" fxLayoutGap="5%">
                <div class="container-head">Hospitality's Cloud-First Approach</div>
                <div *ngIf="mobile" style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/Hicsa-prgm-img2.png">
                </div>
                <div class="info-text">
                    The topic of cloud-based hotel management solutions was a hot button issue at HICSA.
                    Hoteliers
                    we
                    met were excited about the scalability, accessibility, and real-time data insights that
                    cloud
                    systems offer. GoFryday, being a cloud-based platform itself, aligns perfectly with this
                    growing
                    trend. This translates to hotels being able to easily adapt to changing market demands,
                    optimize
                    operations for efficiency, and make data-driven decisions – all crucial aspects for
                    success
                    in
                    today's dynamic hospitality landscape.
                </div>
            </div>
            <div *ngIf="!mobile" style="margin-top: 10px;">
                <img src="assets/images/Hicsa-prgm-img2.png">
            </div>
        </div>
        <div id="revenuesection" fxLayout="row" class="cloud-content">
            <div *ngIf="!mobile" style="margin-top: 10px;">
                <img src="assets/images/Hicsa-prgm-img3.png">
            </div>
            <div fxLayout="column" fxLayoutGap="5%">
                <div class="container-head">How Hotels Are Expanding Their Revenue Horizons</div>
                <div *ngIf="mobile" style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/Hicsa-prgm-img3.png">
                </div>
                <div class="info-text">
                    The hotel industry is buzzing with innovation, not just in guest experiences, but also
                    in revenue generation. While room rates remain a cornerstone, hoteliers are looking beyond
                    the
                    traditional model. This means getting creative with under-utilized spaces – think rooftop
                    yoga
                    classes or pop-up restaurants! – or offering unique experiences like curated tours or
                    workshops.
                    Tech-savvy travelers are also driving new revenue streams, with hotels exploring premium
                    in-room
                    entertainment options. But remember, successful diversification goes hand-in-hand with
                    efficient operations.
                    Here's where GoFryday comes in – we can help streamline these new revenue streams, ensuring
                    your
                    hotel stays ahead of the curve.
                </div>
            </div>
        </div>
        <div id="Sustainabilitysection" fxLayout="column" class="info-content1" fxLayoutGap="2%">
            <div class="container-head">Sustainability: Going Green for Guests and the Planet
            </div>
            <div class="info-text">Sustainability is no longer a niche concern in the travel industry;
                it's a
                core
                value for both hoteliers and travelers. Travelers are eco-conscious, seeking hotels that
                prioritize
                responsible practices. GoFryday is at the forefront of this movement, leveraging technology
                to
                empower
                hotels in creating a more sustainable future. Our platform implements eco-friendly practices
                like
                digital guest communication to reduce paper waste. By providing data-driven insights and
                streamlining
                sustainable initiatives, GoFryday empowers hotels to operate more responsibly, reduce their
                environmental footprint, and resonate with eco-conscious travelers, all while contributing
                to a
                better
                tomorrow.  
            </div>
        </div>
        <div id="conclusionsection" class="conclusion-contents" *ngIf="!mobile">
            <div class="conclusion-box">
                <div fxLayout="column" fxFlex="35%" fxLayoutGap="4%">
                    <div class="conclusion-header">Conclusion: GoFryday - Your Hospitality Tech Partner
                    </div>
                    <div class="conclusion-text">
                        GoFryday is a comprehensive hospitality management platform designed to streamline
                        operations,
                        enhance guest experiences, and drive business growth. If you're looking to harness
                        the
                        power
                        of
                        technology to elevate your hotel, we invite you to reach out and learn more about
                        our
                        innovative
                        solutions
                    </div>
                </div>
            </div>
            <div class="team-image">
                <img src="assets/images/GofryDayTeam.png">
            </div>
        </div>
    </div>
    <div class="conclusion-contents" *ngIf="mobile">
        <div class="conclusion-box">
            <div fxLayout="column" fxLayoutGap="4%">
                <div class="conclusion-header">Conclusion: GoFryday - Your Hospitality Tech Partner</div>
                <div style="place-content: center;display: flex;">
                    <img src="assets/mobile-images/GofryDayTeam.png">
                </div>
                <div class="conclusion-text">
                    GoFryday is a comprehensive hospitality management platform designed to streamline
                    operations,
                    enhance guest experiences, and drive business growth. If you're looking to harness the
                    power
                    of
                    technology to elevate your hotel, we invite you to reach out and learn more about our
                    innovative
                    solutions
                </div>
            </div>
        </div>
    </div>
    <div #learnMoreSection id="learn-more-section" class="learn-more-contents" fxLayout="column" fxLayoutGap="2%">
        <div class="learn-more-head">
            Want To Learn More?
        </div>
        <div class="learn-text">
            Contact GoFryday today to schedule a FREE demo and discover how our solutions can transform your
            hotel!
        </div>
        <div>
            <button class="book-btn"
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                Book a Demo
            </button>
        </div>
    </div>
    <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
        <div class="contact-image" fxLayout="column" fxFlex="50%">
            <div>
                <p class="single-line">We're always happy to hear from you.</p>
            </div>
            <div>
                <img src="assets/images/contact-us.png">
            </div>
        </div>
        <div class="contact-form" fxLayout="column" fxFlex="50%">
            <div class="form-heading">
                Contact Us
            </div>
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                placeholder="Email" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
        <div class="form-heading">
            Contact Us
        </div>
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div style="text-align: center;">
            <img src="assets/images/contact-us.png">
        </div>
        <div class="contact-form" fxLayout="column">
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                                autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>