<app-header></app-header>
<div class="container">
    <div fxLayout="row" class="aboutContainer width100">
        <div class="about-desc">
            We are an AI enabled technology company helping hospitality industry elevate and transform the customer
            experience.
            We are building the futuristic infrastructure for hotels to provide cutting-edge experience for their
            customers and executives.
            <div style="margin-top: 15px;">
                <button class="sales-btn" (click)="selectFreeTrial()">
                    Talk to Sales
                </button>
            </div>
        </div>

    </div>
    <div id="Vision" fxLayout="row" class="visionContainer width100" *ngIf="!mobile">
        <div fxLayout="column" class="vision-content" fxLayoutGap="5%">
            <div class="container-head">Vision</div>
            <div class="vision-text">
                To foster a future where hospitality knows no bounds, leveraging cutting-edge technology to enhance
                every interaction.
            </div>
        </div>
        <div fxLayout="column" class="vision-image">
            <div><img src="assets/images/illustration-background.png"></div>
            <div><img class="top-image" src="assets/images/illustration.png"></div>
        </div>
    </div>
    <div id="Vision" fxLayout="column" class="visionContainer width100" *ngIf="mobile">
        <div fxLayout="column" class="vision-content" fxLayoutGap="5%">
            <div class="container-head">Vision</div>
            <div class="vision-image">
                <img src="assets/mobile-images/illustration-mobile.png" style="width: 100%;">
            </div>
            <div class="vision-text">
                To foster a future where hospitality knows no bounds, leveraging cutting-edge technology to enhance
                every interaction.
            </div>
        </div>
    </div>
    <div id="What-We-Believe-In" fxLayout="column" class="motoContainer width100" fxLayoutGap="5%">
        <div class="container-head" style="text-align: center;">What We Believe In</div>
        <div fxLayout="row" fxLayoutGap="5%" *ngIf="!mobile">
            <div fxLayout="column" fxLayoutGap="3%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/moto1.png">
                    <div class="moto-head">Innovation</div>
                </div>
                <div class="moto-text">
                    We are constantly innovating and developing new and better ways to help our customers succeed. We
                    are not afraid to take risks and try new things.
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/moto2.png">
                    <div class="moto-head">Customer Centric</div>
                </div>
                <div class="moto-text">
                    We are dedicated to our customers and their success. We go the extra mile to understand their needs
                    and to deliver solutions that exceed their expectations.
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/moto3.png">
                    <div class="moto-head">Pursuit of Excellence</div>
                </div>
                <div class="moto-text">
                    We are committed to excellence in everything we do. We strive to exceed our customers' expectations
                    and to set the standard for our industry.
                </div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5%" *ngIf="mobile" style="padding-top: 5%;">
            <div fxLayout="column" fxLayoutGap="3%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/moto1.png">
                    <div class="moto-head">Innovation</div>
                </div>
                <div class="moto-text">
                    We are constantly innovating and developing new and better ways to help our customers succeed. We
                    are not afraid to take risks and try new things.
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/moto2.png">
                    <div class="moto-head">Customer Centric</div>
                </div>
                <div class="moto-text">
                    We are dedicated to our customers and their success. We go the extra mile to understand their needs
                    and to deliver solutions that exceed their expectations.
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/moto3.png">
                    <div class="moto-head">Pursuit of Excellence</div>
                </div>
                <div class="moto-text">
                    We are committed to excellence in everything we do. We strive to exceed our customers' expectations
                    and to set the standard for our industry.
                </div>
            </div>
        </div>
    </div>
    <!-- <div fxLayout="column" class="numberContainer width100" fxLayoutGap="7%">
        <div class="container-head" style="text-align: center;">Numbers Speak</div>
        <div fxLayout="row" fxLayoutGap="5%" *ngIf="!mobile">
            <div fxLayout="column" fxLayoutGap="3%" fxFlex="30%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/number1.png">
                    <div class="digit">300+</div>
                </div>
                <div class="digit-text">
                    More than 300 customers use our solutions and services
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/number2.png">
                    <div class="digit">1000+</div>
                </div>
                <div class="digit-text">
                    More than 1000 projects completed so far
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
                <div fxLayout="row" fxLayoutGap="5%" style="align-items: center;">
                    <img src="assets/images/number3.png">
                    <div class="digit">100+</div>
                </div>
                <div class="digit-text">
                    More than 100 employees across 4 countries
                </div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="10%" *ngIf="mobile" style="padding-top: 5%; text-align: center;">
            <div fxLayout="column" fxLayoutGap="8%">
                <div><img src="assets/images/number1.png"></div>
                <div class="digit">300+</div>
                <div class="digit-text">
                    More than 300 customers use our solutions and services
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="8%">
                <div><img src="assets/images/number2.png"></div>
                <div class="digit">1000+</div>
                <div class="digit-text">
                    More than 1000 projects completed so far
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="8%">
                <div><img src="assets/images/number3.png"></div>
                <div class="digit">100+</div>
                <div class="digit-text">
                    More than 100 employees across 4 countries
                </div>
            </div>
        </div>
    </div> -->
    <div id="Team" fxLayout="column" class="teamContainer width100" fxLayoutGap="5%">
        <div class="container-head" style="text-align: center;">Team</div>
        <div fxLayout="column">
            <div fxLayout="row" fxLayoutGap="4%" class="member-row" fxLayoutAlign="space-evenly center">
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Tony.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Tony Mathew</div>
                        <div class="text2">CEO</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Visha.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Visha Chauhan</div>
                        <div class="text2">COO</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Suraj.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Suraj Abhishek</div>
                        <div class="text2">Product Manager</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <div class="team-photo">
                        <img src="assets/images/user.png" class="temp-user">
                    </div>
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Varun Chakervarti</div>
                        <div class="text2">Associate Architect</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Srivani.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Thalla Sai Srivani</div>
                        <div class="text2">UX Designer</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Janhavi.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Janhavi Srivastava</div>
                        <div class="text2">Marketing Manager</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <!-- <div class="team-photo"> -->
                    <img src="assets/images/janmejaya-das-photo.jpg" class="team-photo">
                    <!-- </div> -->
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Janmejaya Das</div>
                        <div class="text2">Sr.Software Developer</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Shikha.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Shikha Dawre</div>
                        <div class="text2">Sr.Software Developer</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Amjad.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Amjad Ali</div>
                        <div class="text2">Sr.Software Developer</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Merin.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Merin Annie Vincent</div>
                        <div class="text2">Software Engineer</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Rashmi.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Rashmi D</div>
                        <div class="text2">Software Engineer</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Nikitha.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Nikitha Elsa Thomas</div>
                        <div class="text2">Software Engineer</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Sreelekshmi.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Sreelekshmi SR</div>
                        <div class="text2">Software Engineer</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="10%" class="member-div">
                    <img src="assets/images/Manjunath.png" class="team-photo">
                    <div fxLayout="column" fxLayoutGap="5%" class="team-detail">
                        <div class="text1">Manjunath GK</div>
                        <div class="text2">Software Engineer</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
        <div class="contact-image" fxLayout="column" fxFlex="50%">
            <div>
                <p class="single-line">We're always happy to hear from you.</p>
            </div>
            <div>
                <img src="assets/images/contact-us.png">
            </div>
        </div>
        <div class="contact-form" fxLayout="column" fxFlex="50%">
            <div class="form-heading">
                Contact Us
            </div>
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                placeholder="Email" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
        <div class="form-heading">
            Contact Us
        </div>
        <div>
            <p class="single-line">We're always happy to hear from you.</p>
        </div>
        <div style="text-align: center;">
            <img src="assets/images/contact-us.png">
        </div>
        <div class="contact-form" fxLayout="column">
            <div class="form">
                <form [formGroup]="contactUsForm">
                    <div fxLayout="column" fxLayoutGap="5%">
                        <div>
                            <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                maxlength="200" autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div>
                            <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                                autocomplete="off">
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                Required
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                Please provide a valid Email Id
                            </mat-error>
                        </div>
                        <div>
                            <textarea class="text-area" formControlName="message" placeholder="Write message"
                                maxlength="1500" autocomplete="off"></textarea>
                            <mat-error class="mat-error"
                                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                Required
                            </mat-error>
                        </div>
                        <div fxLayout="row" fxLayoutGap="2%">
                            <input type="checkbox" class="check-box" formControlName="tnc" />
                            <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                    (click)="viewPrivacyPolicy()">"Privacy
                                    Policy"</a> and
                                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                    Conditions"</a></span>
                        </div>
                    </div>
                </form>
            </div>
            <div style="text-align: center;">
                <button
                    [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                    (click)="submitContactForm()"
                    [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>