<div class="footer" fxLayout="column" FxLayoutGap="3%" *ngIf="!mobile">
    <div fxLayout="row" class="footer1" fxLayoutAlign="space-between">
        <div fxLayout="column" fxLayoutGap="14px">
            <div>
                <span class="address-label">About Us</span>
            </div>
            <div>
                <span class="address" (click)="viewVision()">Vision</span>
            </div>
            <div>
                <span class="address" (click)="viewBelieveIn()">What We Believe In</span>
            </div>
            <div>
                <span class="address" (click)="viewTeam()">Team</span>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="14px">
            <div>
                <span class="address-label">Plans</span>
            </div>
            <div>
                <span class="address" (click)="viewPricing()">Pricing</span>
            </div>
            <div>
                <span class="address" (click)="viewTalktoSales()">Talk to Sales</span>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="14px">
            <div>
                <span class="address-label">Features</span>
            </div>
            <div>
                <span class="address" (click)="viewGuestApp()">Guest App</span>
            </div>
            <div>
                <span class="address" (click)="viewClio()">CLIO</span>
            </div>
            <div>
                <span class="address" (click)="viewTaskApp()">Task App</span>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="14px">
            <div>
                <span class="address-label">Blog</span>
            </div>
            <div>
                <span class="address" (click)="viewHicsa()">HICSA 2024</span>
            </div>
            <div>
                <span class="address" (click)="viewAIHospitality()">AI In Hospitality</span>
            </div>
            <div>
                <span class="address" (click)="viewHotelCRM()">Hotel CRM</span>
            </div>
        </div>
        <div fxLayout="column" fxFlex="35%" fxLayoutGap="12px">
            <div style="width:90%">
                <span class="uptodate-text">Stay upto date! We send out a newsletter monthly with our latest
                    news</span>
            </div>
            <div>
                <input maxlength="200" placeholder="Enter Your Email" [formControl]="email" class="email-field">
                <mat-error class="mat-error" *ngIf="email.hasError('pattern')">Please provide a valid Email
                    Id</mat-error>
            </div>
            <div>
                <button class="subscribe-btn" (click)="subscribe()"
                    [disabled]="email.value == '' || email.hasError('pattern') || disabled">Subscribe
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="footer2">
        <div fxLayout="row" fxLayoutGap="40px">
            <div>
                <span class="label click" (click)="viewTermsConditions()">Terms and Conditions</span>
            </div>
            <div>
                <span class="label click" (click)="viewPrivacyPolicy()">Privacy Policy</span>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="30px">
            <div>
                <iconify-icon icon="mingcute:linkedin-line" class="social-media-icon"
                    (click)="viewLinkedIn()"></iconify-icon>
            </div>
            <div>
                <iconify-icon icon="ant-design:youtube-outlined" class="social-media-icon"
                    (click)="viewYoutube()"></iconify-icon>
            </div>
            <div>
                <span class="label">©2023 GOFRYDAY</span>
            </div>
        </div>
    </div>
    <div class="vector">
        <img src="assets/images/GoFryDay-Vector.png">
    </div>
</div>

<div class="footer" fxLayout="column" FxLayoutGap="3%" *ngIf="mobile">
    <div fxLayout="column" class="footer1" fxLayoutGap="18px">
        <div fxLayout="column" fxLayoutGap="14px">
            <div fxLayout="column" fxLayoutGap="10px">
                <div>
                    <span class="address-label">About Us</span>
                </div>
                <div>
                    <span class="address" (click)="viewVision()">Vision</span>
                </div>
                <div>
                    <span class="address" (click)="viewBelieveIn()">What We Believe In</span>
                </div>
                <div>
                    <span class="address" (click)="viewTeam()">Team</span>
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
                <div>
                    <span class="address-label">Plans</span>
                </div>
                <div>
                    <span class="address" (click)="viewPricing()">Pricing</span>
                </div>
                <div>
                    <span class="address" (click)="viewTalktoSales()">Talk to Sales</span>
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
                <div>
                    <span class="address-label">Features</span>
                </div>
                <div>
                    <span class="address" (click)="viewGuestApp()">Guest App</span>
                </div>
                <div>
                    <span class="address" (click)="viewClio()">CLIO</span>
                </div>
                <div>
                    <span class="address" (click)="viewTaskApp()">Task App</span>
                </div>
            </div>
            <div fxLayout="column" fxLayoutGap="10px">
                <div>
                    <span class="address-label">Blog</span>
                </div>
                <div>
                    <span class="address" (click)="viewHicsa()">HICSA 2024</span>
                </div>
                <div>
                    <span class="address" (click)="viewAIHospitality()">AI In Hospitality</span>
                </div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="12px">
            <div>
                <span class="uptodate-text">Stay upto date! We send out a newsletter monthly with our latest
                    news</span>
            </div>
            <div>
                <input matInput placeholder="Enter Your Email" [formControl]="email" class="email-field">
                <mat-error class="mat-error" *ngIf="email.hasError('pattern')">Please provide a valid Email
                    Id</mat-error>
            </div>
            <div>
                <button class="subscribe-btn" (click)="subscribe()"
                    [disabled]="email.value == '' || email.hasError('pattern') || disabled">Subscribe
                    <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="footer2" fxLayoutGap="11px">
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="0px">
            <div>
                <span class="label">©2023 GOFRYDAY</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
                <div>
                    <iconify-icon icon="mingcute:linkedin-line" class="social-media-icon"
                        (click)="viewLinkedIn()"></iconify-icon>
                </div>
                <div>
                    <iconify-icon icon="ant-design:youtube-outlined" class="social-media-icon"
                        (click)="viewYoutube()"></iconify-icon>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <div>
                <span class="label click" (click)="viewTermsConditions()">Terms and Conditions</span>
            </div>
            <div>
                <span class="label click" (click)="viewPrivacyPolicy()">Privacy Policy</span>
            </div>
        </div>
    </div>
    <div class="vector" fxLayoutAlign="center">
        <img src="assets/mobile-images/GoFryDay-Vector.png">
    </div>
</div>