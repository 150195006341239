<div mat-dialog-content fxLayout="column" fxLayoutAlign="center center" class="content">
  <div *ngIf="data.name == 'free-trial'">
    <img src="assets/images/verified.png">
  </div>
  <span class="message">{{data.message}}</span>
  <div *ngIf="data.name != 'free-trial'">
    <button mat-button class="ok-btn" [mat-dialog-close] cdkFocusInitial>OK</button>
  </div>
  <div *ngIf="data.name == 'free-trial'">
    <button mat-button class="ok-btn" (click)="done()" [mat-dialog-close] cdkFocusInitial>Done</button>
  </div>
</div>