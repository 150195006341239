import { Component, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/shared/color-object';
import { register } from 'swiper/element/bundle';


register();
@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.css']
})
export class ExperienceComponent {

  goaPlacesList: any = [
    {
      name: 'Palolem Beach',
      image: '../../../assets/images/palolem_beach.jpg'
    },
    {
      name: 'Dudhsagar Falls',
      image: '../../../assets/images/dudhsagar_falls.jpg'
    },
    {
      name: 'Colva Beach',
      image: '../../../assets/images/colva_beach.jpg'
    },
    {
      name: 'Vagator Beach',
      image: '../../../assets/images/vagator_beach.jpg'
    },
    {
      name: 'Cavelossim Beach',
      image: '../../../assets/images/cavelossim_beach.jpg'
    },
    {
      name: 'Morjim Beach',
      image: '../../../assets/images/morjim_beach.jpg'
    },
    {
      name: 'Arambol Beach',
      image: '../../../assets/images/arambol_beach.jpg'
    },
    {
      name: 'Anjuna Beach',
      image: '../../../assets/images/anjuna_beach.jpg'
    },
    {
      name: 'Varca Beach',
      image: '../../../assets/images/varca_beach.jpg'
    },
    {
      name: 'Benaulim Beach',
      image: '../../../assets/images/benaulim_beach.jpg'
    },
    {
      name: 'Fort Aguada',
      image: '../../../assets/images/fort_aguada.jpg'
    },
    {
      name: 'Miramar Beach',
      image: '../../../assets/images/miramar_beach.jpg'
    },
    {
      name: 'Agonda Beach',
      image: '../../../assets/images/agonda_beach.jpg'
    }
  ];
  loading: boolean = true;
  experienceCities: any = [];
  pushkarPackages: any = [];
  goaPackages: any = [];
  mobilePushkarPackages: any = [];
  mobileGoaPackages: any = [];
  public bucketUrl = `${environment.BUCKET_URL}`;
  pagination = {
    pagination: {
      el: '.swiper-pagination',
      modifierClass: 'pagination',
      bulletClass: 'swiper-pagination-bullet'
    }
  }
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  @ViewChild('inspirePlaces', {}) public inspirePlaces;
  @ViewChild('pushkarAttractions', {}) public pushkarAttractions;
  @ViewChild('goaAttractions', {}) public goaAttractions;

  constructor(
    private hotelService: HotelService,
    private router: Router,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.getCities();
    // this.getGoaPackages();
    // this.getPushkarPackages();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getCities() {
    this.hotelService.getExperienceCity().subscribe({
      next: (data) => {
        this.experienceCities = data.object;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
    this.getGoaPackages();
  }

  getGoaPackages() {
    this.hotelService.getExperiencePackages(1).subscribe({
      next: (data) => {
        this.goaPackages = data.object;
        for (let i = 0; i < 3; i++) {
          this.mobileGoaPackages.push(this.goaPackages[i]);
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
    this.getPushkarPackages();
  }

  getPushkarPackages() {
    this.hotelService.getExperiencePackages(2).subscribe({
      next: (data) => {
        this.loading = false;
        this.pushkarPackages = data.object;
        for (let i = 0; i < 3; i++) {
          this.mobilePushkarPackages.push(this.pushkarPackages[i]);
        }
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  seeDetailedList(cityId) {
    localStorage.setItem("SELECTED_CITY", cityId);
    this.hotelService.setSelectedPlace(cityId);
    this.router.navigateByUrl('experience-details');
    window.scrollTo(0, 0);
  }

  reservePackage(packageId) {
    localStorage.setItem("SELECTED_PACKAGE", packageId);
    this.router.navigateByUrl('reserve');
    window.scrollTo(0, 0);
  }

  public bannerScrollRight(): void {
    this.inspirePlaces.nativeElement.scrollTo({ left: (this.inspirePlaces.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public bannerScrollLeft(): void {
    this.inspirePlaces.nativeElement.scrollTo({ left: (this.inspirePlaces.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

  public pushkarScrollRight(): void {
    this.pushkarAttractions.nativeElement.scrollTo({ left: (this.pushkarAttractions.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public pushkarScrollLeft(): void {
    this.pushkarAttractions.nativeElement.scrollTo({ left: (this.pushkarAttractions.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

  public goaScrollRight(): void {
    this.goaAttractions.nativeElement.scrollTo({ left: (this.goaAttractions.nativeElement.scrollLeft + 500), behavior: 'smooth' });
  }

  public goaScrollLeft(): void {
    this.goaAttractions.nativeElement.scrollTo({ left: (this.goaAttractions.nativeElement.scrollLeft - 500), behavior: 'smooth' });
  }

}