import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HotelService {

  public resourceUrl = `${environment.API_BASE_URL}`;
  private selectedPlace = new BehaviorSubject<string>('');

  constructor(private http: HttpClient) { }

  private popupOpen = new BehaviorSubject(false);
  hideContent = this.popupOpen.asObservable();

  isHideStickyContent(popup) {
    this.popupOpen.next(popup);
  }

  setSelectedPlace(value) {
    this.selectedPlace.next(value);
  }

  getSelectedPlace() {
    return this.selectedPlace.asObservable();
  }

  getMasterData(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/master-data`, {});
  }

  subscribe(obj: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/guest/subscribe`, obj);
  }

  connect(obj: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/guest/connect`, obj);
  }

  freeTrial(obj: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/guest/trial`, obj);
  }

  contactUs(obj: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/guest/contact-us`, obj);
  }

  getExperienceCity(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/guest/experience/package/city`);
  }

  getExperiencePackageCategory(cityId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/guest/experience/package/category?CITY_ID=${cityId}`);
  }

  getExperiencePackages(cityId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/guest/experience/package?CITY_ID=${cityId}`);
  }

  getExperiencePackageById(packageId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/guest/experience/package?ID=${packageId}`);
  }

  reserveExperience(obj: any): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/guest/experience/reserve`, obj);
  }

}