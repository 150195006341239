import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FreeTrialComponent } from './pages/free-trial/free-trial.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { PlansComponent } from './pages/plans/plans.component';
import { FeaturesPageComponent } from './pages/features-page/features-page.component';
import { PartnerProgramComponent } from './pages/partner-program/partner-program.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ExperienceDetailComponent } from './pages/experience-detail/experience-detail.component';
import { ReserveComponent } from './pages/reserve/reserve.component';
import { Blog1Component } from './pages/blog1/blog1.component';
import { Blog2Component } from './pages/blog2/blog2.component';
import { Blog3Component } from './pages/blog3/blog3.component';
import { GuestAppComponent } from './pages/guest-app/guest-app.component';
import { ClioComponent } from './pages/clio/clio.component';
import { TaskAppComponent } from './pages/task-app/task-app.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    component: HomePageComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'free-trial',
    component: FreeTrialComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'features',
    component: FeaturesPageComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'features/guest-app',
    component: GuestAppComponent,
    data: {
      title: "Guest App: Hotel Guest Experience Software | GoFryDay",
      description: "Enhance your hospitality with our hotel guest experience software. Boost guest satisfaction and streamline operations effortlessly. Contact us now!",
      ogTitle: "Guest App: Hotel Guest Experience Software | GoFryDay",
      ogDescription: "Enhance your hospitality with our hotel guest experience software. Boost guest satisfaction and streamline operations effortlessly. Contact us now!"
    }
  },
  {
    path: 'features/clio',
    component: ClioComponent,
    data: {
      title: "Clio: Leading Hospitality Management Software | GoFryDay",
      description: "Streamline your hotel operations with our comprehensive hospitality management software to manage inventory, handle complaints. Contact us now!",
      ogTitle: "Clio: Leading Hospitality Management Software | GoFryDay",
      ogDescription: "Streamline your hotel operations with our comprehensive hospitality management software to manage inventory, handle complaints. Contact us now!"
    }
  },
  {
    path: 'features/task-app',
    component: TaskAppComponent,
    data: {
      title: "Task App: Hotel Task Management Software | GoFryDay",
      description: "Efficiently manage hotel tasks with our hotel task management software. Simplify guest requests and automate tasks with our powerful app. Contact us now!",
      ogTitle: "Task App: Hotel Task Management Software | GoFryDay",
      ogDescription: "Efficiently manage hotel tasks with our hotel task management software. Simplify guest requests and automate tasks with our powerful app. Contact us now!"
    }
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'plans',
    component: PlansComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'blog',
    component: BlogPageComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'blog/hicsa',
    component: Blog1Component,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'blog/guest-centric-revolution',
    component: Blog2Component,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'blog/hotel-CRM',
    component: Blog3Component,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'partner-program',
    component: PartnerProgramComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'experiences',
    component: ExperienceComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'experience-details',
    component: ExperienceDetailComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'reserve',
    component: ReserveComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
    data: {
      title: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      description: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction",
      ogTitle: "GoFryDay Hotel Management Software: Rated #1 For Efficiency",
      ogDescription: "GoFryDay Hotel Management Software excels in efficiency, simplifying hotel operations, boosting productivity, and ensuring exceptional guest satisfaction"
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }