<app-header></app-header>
<div class="container" fxLayout="column">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div class="titleContainer width100" fxLayout="column" *ngIf="!loading">
        <div class="header-container" fxLayout="row" fxLayoutAlign="center">
            <div class="exp-title">Enjoy Unforgettable Experiences With <span class="exp-title1">GoFryDay</span></div>
        </div>
        <div class="title-img-container">
            <img class="title-img" src="../../../assets/images/experience-title.png">
        </div>
    </div>
    <div class="cityContainer width100" fxLayout="row" *ngIf="!mobile && !loading">
        <div fxLayout="column" fxFlex="50%" fxLayoutAlign="center start">
            <div class="explore-title">Cities</div>
            <div class="explore-desc">Explore Activities in Goa and Pushkar</div>
            <img class="explore-img" src="../../../assets/images/explore.png">
        </div>
        <div fxLayout="row" fxFlex="50%" fxLayoutAlign="space-between center">
            <div class="image-container" *ngFor="let city of experienceCities; let i = index"
                (click)="seeDetailedList(city.ID)" [ngStyle]="{'top': i === 0 ? '50px' : '0px' }">
                <img src="{{bucketUrl + city.IMAGE}}">
                <div class="overlay-text">{{city.NAME}}</div>
            </div>
        </div>
    </div>
    <div class="cityContainer width100" fxLayout="column" *ngIf="mobile && !loading" fxLayoutGap="3%">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="explore-title">Cities</div>
            <div class="explore-desc">Explore Activities in Goa and Pushkar</div>
            <img class="explore-img" src="../../../assets/images/explore.png">
        </div>
        <div fxLayout="row" fxFlex="50%" fxLayoutAlign="space-evenly center">
            <div class="image-container" *ngFor="let city of experienceCities" (click)="seeDetailedList(city.ID)">
                <img src="{{bucketUrl + city.IMAGE}}">
                <div class="overlay-text">{{city.NAME}}</div>
            </div>
        </div>
    </div>
    <div class="inspirationContainer width100" fxLayout="column" *ngIf="!loading">
        <div class="inspire-title">
            Inspirations for your Next Vacation to Goa
        </div>
        <div fxLayout="row" *ngIf="!mobile" style="margin-top: 2%;">
            <div style="align-self: center;" *ngIf="goaPlacesList.length != 0">
                <mat-button (click)="bannerScrollLeft()" class="scroll-btn">
                    <iconify-icon icon="material-symbols:keyboard-arrow-left" class="navigate-btn"></iconify-icon>
                </mat-button>
            </div>
            <div class="inspire-places" #inspirePlaces fxLayout="row" fxLayoutGap="10px">
                <div *ngFor="let list of goaPlacesList" class="banner-container">
                    <img class="banner-img" [src]="list.image">
                    <div class="banner-text">{{list.name}}</div>
                </div>
            </div>
            <div style="align-self: center;" *ngIf="goaPlacesList.length != 0">
                <mat-button (click)="bannerScrollRight()" class="scroll-btn">
                    <iconify-icon icon="material-symbols:keyboard-arrow-right" class="navigate-btn"></iconify-icon>
                </mat-button>
            </div>
        </div>
        <div class="overlay-parent" *ngIf="mobile">
            <swiper-container [slidesPerView]="1" [pagination]="true">
                <swiper-slide class="blank-img" *ngFor="let list of goaPlacesList">
                    <img class="banner-img-mobile" src="{{list.image}}">
                    <div class="banner-text">{{list.name}}</div>
                </swiper-slide>
            </swiper-container>
        </div>
    </div>
    <div class="pushkarContainer width100" fxLayout="column" *ngIf="!mobile && !loading">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin: 0 5%;">
            <div class="inspire-title">
                Top Attractions in Pushkar
            </div>
            <div class="see-all" (click)="seeDetailedList(2)">See All</div>
        </div>
        <div fxLayout="row">
            <div style="align-self: center;">
                <mat-button (click)="pushkarScrollLeft()" class="scroll-btn">
                    <iconify-icon icon="material-symbols:keyboard-arrow-left" class="navigate-btn"></iconify-icon>
                </mat-button>
            </div>
            <div class="inspire-places" #pushkarAttractions fxLayout="row" fxLayoutGap="10px">
                <mat-card fxLayout="column" class="attraction-card" *ngFor="let package of pushkarPackages">
                    <div fxFlex="50%">
                        <img class="detail-pic" src="{{bucketUrl + package.IMAGE}}">
                    </div>
                    <div fxFlex="50%" class="attraction-details" fxLayout="column" fxLayoutGap="5px"
                        fxLayoutAlign="space-around start">
                        <div class="detail-title">{{package.TITLE}}</div>
                        <div>
                            <iconify-icon icon="material-symbols:star-rounded"
                                *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                [ngClass]="i < package.RATING ? 'star' : 'star-outline'"></iconify-icon>
                        </div>
                        <div class="detail-desc">
                            {{package.DESCRIPTION}}
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                            <div class="detail-price">&#8377;{{package.AMOUNT}}<span class="price-desc">per adult</span>
                            </div>
                            <div>
                                <button mat-button class="reserve-btn"
                                    (click)="reservePackage(package.ID)">Reserve</button>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div style="align-self: center;">
                <mat-button (click)="pushkarScrollRight()" class="scroll-btn">
                    <iconify-icon icon="material-symbols:keyboard-arrow-right" class="navigate-btn"></iconify-icon>
                </mat-button>
            </div>
        </div>
    </div>
    <div class="pushkarContainer width100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4%"
        *ngIf="mobile && !loading">
        <div fxLayout="row" fxLayoutAlign="center center">
            <div class="inspire-title" style="width: 80%;">
                Top Attractions in Pushkar
            </div>
        </div>
        <mat-card fxLayout="column" class="attraction-card" *ngFor="let package of mobilePushkarPackages">
            <div fxFlex="50%">
                <img class="detail-pic" src="{{bucketUrl + package.IMAGE}}">
            </div>
            <div fxFlex="50%" class="attraction-details" fxLayout="column" fxLayoutGap="5px"
                fxLayoutAlign="center start">
                <div class="detail-title">{{package.TITLE}}</div>
                <div>
                    <iconify-icon icon="material-symbols:star-rounded"
                        *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                        [ngClass]="i < package.RATING ? 'star' : 'star-outline'"></iconify-icon>
                </div>
                <div class="detail-desc">
                    {{package.DESCRIPTION}}
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                    <div class="detail-price">&#8377;{{package.AMOUNT}}<span class="price-desc">per adult</span></div>
                    <div>
                        <button mat-button class="reserve-btn" (click)="reservePackage(package.ID)">Reserve</button>
                    </div>
                </div>
            </div>
        </mat-card>
        <div class="see-all" (click)="seeDetailedList(2)">See All</div>
    </div>
    <div class="goaContainer width100" fxLayout="column" *ngIf="!mobile && !loading">
        <div fxLayout="row" fxLayoutAlign="space-between center" style="margin: 0 5%;">
            <div class="inspire-title">
                Top Attractions in Goa
            </div>
            <div class="see-all" (click)="seeDetailedList(1)">See All</div>
        </div>
        <div fxLayout="row">
            <div style="align-self: center;">
                <mat-button (click)="goaScrollLeft()" class="scroll-btn">
                    <iconify-icon icon="material-symbols:keyboard-arrow-left" class="navigate-btn"></iconify-icon>
                </mat-button>
            </div>
            <div class="inspire-places" #goaAttractions fxLayout="row" fxLayoutGap="10px">
                <mat-card fxLayout="column" class="attraction-card" *ngFor="let package of goaPackages">
                    <div fxFlex="50%">
                        <img class="detail-pic" src="{{bucketUrl + package.IMAGE}}">
                    </div>
                    <div fxFlex="50%" class="attraction-details" fxLayout="column" fxLayoutGap="5px"
                        fxLayoutAlign="space-around start">
                        <div class="detail-title">{{package.TITLE}}</div>
                        <div>
                            <iconify-icon icon="material-symbols:star-rounded"
                                *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                [ngClass]="i < package.RATING ? 'star' : 'star-outline'"></iconify-icon>
                        </div>
                        <div class="detail-desc">
                            {{package.DESCRIPTION}}
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                            <div class="detail-price">&#8377;{{package.AMOUNT}}<span class="price-desc">per adult</span>
                            </div>
                            <div>
                                <button mat-button class="reserve-btn"
                                    (click)="reservePackage(package.ID)">Reserve</button>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
            <div style="align-self: center;">
                <mat-button (click)="goaScrollRight()" class="scroll-btn">
                    <iconify-icon icon="material-symbols:keyboard-arrow-right" class="navigate-btn"></iconify-icon>
                </mat-button>
            </div>
        </div>
    </div>
    <div class="goaContainer width100" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4%"
        *ngIf="mobile && !loading">
        <div fxLayout="row" fxLayoutAlign="center center">
            <div class="inspire-title" style="width: 90%;">
                Top Attractions in Goa
            </div>
        </div>
        <mat-card fxLayout="column" class="attraction-card" *ngFor="let package of mobileGoaPackages">
            <div fxFlex="50%">
                <img class="detail-pic" src="{{bucketUrl + package.IMAGE}}">
            </div>
            <div fxFlex="50%" class="attraction-details" fxLayout="column" fxLayoutGap="5px"
                fxLayoutAlign="center start">
                <div class="detail-title">{{package.TITLE}}</div>
                <div>
                    <iconify-icon icon="material-symbols:star-rounded"
                        *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                        [ngClass]="i < package.RATING ? 'star' : 'star-outline'"></iconify-icon>
                </div>
                <div class="detail-desc">
                    {{package.DESCRIPTION}}
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%;">
                    <div class="detail-price">&#8377;{{package.AMOUNT}}<span class="price-desc">per adult</span></div>
                    <div>
                        <button mat-button class="reserve-btn" (click)="reservePackage(package.ID)">Reserve</button>
                    </div>
                </div>
            </div>
        </mat-card>
        <div class="see-all" (click)="seeDetailedList(1)">See All</div>
    </div>
</div>
<app-footer></app-footer>