<app-header></app-header>
<mat-card *ngIf="loading" [ngClass]="{'reserve-container-web' : !mobile , 'reverse-container-mob' : mobile}">
    <div class="overlay">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
</mat-card>
<mat-card [ngClass]="{'reserve-container-web' : !mobile , 'reverse-container-mob' : mobile}"
    *ngIf="!orderDetails && !bookingConfirm && !loading">
    <div>
        <div class="reserve-container">
            <div class="back-arrow" (click)="backButton()">
                <iconify-icon icon="material-symbols:arrow-back-rounded" class="arrow-btn"></iconify-icon>
            </div>
            <span>
                <h2 class="reserve-header">Please fill the form to reserve your spot</h2>
            </span>
        </div>
        <div>
            <form [formGroup]="reserveForm">
                <div fxLayout="column" class="form-container">
                    <div fxLayout="row" fxLayoutGap="0%" class="input-container" *ngIf="!mobile">
                        <div class="input-container-address">
                            <input class="theme-input-name" type="text" placeholder="First Name" name="firstName"
                                formControlName="firstName" maxlength="100" autocomplete="off" required />
                            <div class="validation-error"
                                *ngIf=" reserveForm.get('firstName')?.invalid && reserveForm.get('firstName')?.touched && reserveForm.controls['firstName']?.errors?.['required']">
                                Please provide a valid name</div>
                            <div class="validation-error"
                                *ngIf="reserveForm.get('firstName')?.hasError('pattern') || reserveForm.get('firstName')?.hasError('minlength') || reserveForm.get('firstName')?.hasError('maxlength')">
                                Min 3 & max length 100, alphabets only
                            </div>
                        </div>
                        <div class="input-container-address">
                            <input class="theme-input-name" type="text" placeholder="Last Name" name="lastName"
                                formControlName="lastName" maxlength="100" autocomplete="off" required />
                            <div class="validation-error"
                                *ngIf="reserveForm.get('lastName')?.touched && reserveForm.controls['lastName']?.errors?.['required']">
                                Please provide a valid name</div>
                            <div class="validation-error"
                                *ngIf="reserveForm.get('lastName')?.hasError('pattern') || reserveForm.get('lastName')?.hasError('maxlength')">
                                Max length 100, alphabets only
                            </div>
                        </div>
                    </div>
                    <div class="input-container-address" *ngIf="mobile">
                        <input class="theme-input-name" type="text" placeholder="First Name" name="firstName"
                            formControlName="firstName" maxlength="100" autocomplete="off" required />
                        <div class="validation-error"
                            *ngIf=" reserveForm.get('firstName')?.invalid && reserveForm.get('firstName')?.touched && reserveForm.controls['firstName']?.errors?.['required']">
                            Please provide a valid name</div>
                        <div class="validation-error"
                            *ngIf="reserveForm.get('firstName')?.hasError('pattern') || reserveForm.get('firstName')?.hasError('minlength') || reserveForm.get('firstName')?.hasError('maxlength')">
                            Min 3 & max length 100, alphabets only
                        </div>
                    </div>
                    <div class="input-container-address" *ngIf="mobile">
                        <input class="theme-input-name" type="text" placeholder="Last Name" name="lastName"
                            formControlName="lastName" maxlength="100" autocomplete="off" required />
                        <div class="validation-error"
                            *ngIf="reserveForm.get('lastName')?.touched && reserveForm.controls['lastName']?.errors?.['required']">
                            Please provide a valid name</div>
                        <div class="validation-error"
                            *ngIf="reserveForm.get('lastName')?.hasError('pattern') || reserveForm.get('lastName')?.hasError('maxlength')">
                            Max length 100, alphabets only
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="0%" class="input-container-address">
                        <div>
                            <input class="theme-input-email" type="text" placeholder="Enter Email ID" name="emailId"
                                formControlName="emailId" autocomplete="off" required />
                        </div>
                        <div class="validation-error"
                            *ngIf="reserveForm.get('emailId')?.touched && reserveForm.controls['emailId']?.errors?.['required']">
                            Please
                            provide a valid Email ID</div>
                        <div class="validation-error" *ngIf="reserveForm.get('emailId')?.errors?.['pattern']">
                            Enter Valid Email
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="0%" class="input-container-field">
                        <div class="input-container-phno">
                            <mat-select panelClass="codeClass" class="theme-input-phcode" formControlName="phoneCode"
                                name="country" [placeholder]="countryCode"
                                (selectionChange)="changeCountry($event.value)"
                                (value)="reserveForm.get('phoneCode')?.value">
                                <mat-select-trigger>{{countryCode}}</mat-select-trigger>
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel="Search" [formControl]="country">
                                        <mat-icon ngxMatSelectSearchClear class="close-icon">close</mat-icon>
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let country of filterList" [value]="country.ID">
                                    <div fxLayout="row" class="options">
                                        <div class="c-code">+{{ country.CODE }}</div>
                                        <div class="c-name">{{ country.NAME }}</div>
                                    </div>
                                </mat-option>
                            </mat-select>

                            <input class="theme-input-ph" type="text" placeholder="Phone Number" name="phoneNumber"
                                formControlName="phoneNumber" autocomplete="off" required />
                        </div>
                        <div fxLayout="column">
                            <div class="validation-error" *ngIf="reserveForm.get('phoneNumber')?.hasError('pattern')">
                                Phone number must contain only numbers and shouldn't exceed 50 digits
                            </div>
                            <div class="validation-error"
                                *ngIf="reserveForm.get('phoneNumber')?.touched && reserveForm.controls['phoneNumber']?.errors?.['required']">
                                Phone number is required
                            </div>
                        </div>
                    </div>
                    <div class="input-container-address">
                        <mat-form-field class="input-field_cmpname" appearance="outline">
                            <input matInput [matDatepicker]="picker" formControlName="date" [min]="currentDate"
                                placeholder="Select Date" readonly>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="input-container-address">
                        <input class="theme-input-email" type="text" placeholder="Number of People" name="guest"
                            formControlName="guest" autocomplete="off" required [matMenuTriggerFor]="menu" readonly />
                        <mat-menu #menu="matMenu" panelClass="custom-menu" (closed)="menuClosed()">
                            <div class="menu-bar">
                                <span class="guest-header">Add Guests</span>
                                <div fxLayout="column" fxLayoutGap="6%" class="guest-menu">
                                    <div class="adult-row">
                                        <div fxLayout="column">
                                            <span class="guest-type">Adult</span>
                                            <span class="guest-age">Age&nbsp;above&nbsp;18</span>
                                        </div>
                                        <button class="remove-button" (click)="decreaseAdultCount($event)">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                        <span class="no-button">{{ adultCount }}</span>
                                        <button class="remove-button" (click)="increaseAdultCount($event)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                    <div class="adult-row">
                                        <div fxLayout="column">
                                            <span class="guest-type">Child</span>
                                            <span class="guest-age">Age&nbsp;below&nbsp;10</span>
                                        </div>
                                        <button class="remove-button" (click)="decreaseChildCount($event)">
                                            <mat-icon>remove</mat-icon>
                                        </button>
                                        <span class="no-button">{{ childCount }}</span>
                                        <button class="remove-button" (click)="increaseChildCount($event)">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                    <div fxLayoutAlign="center center" class="guest-count-update">
                                        <button mat-button class="next-btn" (click)="updateCounts()">Done</button>
                                    </div>
                                </div>
                            </div>
                        </mat-menu>
                    </div>
                    <div>
                        <button (click)="orderDetailsForm()" type="submit"
                            [ngClass]="this.reserveForm.invalid ? 'invalid-btn' : 'next-btn'"
                            [disabled]="this.reserveForm.invalid || disabled">
                            Next
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</mat-card>
<mat-card class="order-container-web" *ngIf="(orderDetails || bookingConfirm) && !loading">
    <div *ngIf="orderDetails">
        <div class="order-container">
            <div class="back-arrow" style="margin-top: 3px;" (click)="formbackButton()">
                <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
            </div>
            <span>
                <h2 class="order-header">Order Details</h2>
            </span>
        </div>
        <div class="order-details">
            <div fxLayout="column" fxLayoutGap="3%">
                <span class="order-sub-header">{{experiencePackage[0]?.TITLE}}</span>
                <span class="order-details-text">{{experiencePackage[0]?.DURATION}}</span>
                <span class="order-details-text">{{this.reserveForm.value.date | date:'dd/MM/YYYY'}}</span>
                <span class="order-details-text">Adult: {{adultCount}}, Child: {{childCount}}</span>
            </div>
            <div>
                <img class="detail-image" src="{{bucketUrl + experiencePackage[0]?.IMAGE}}" />
            </div>
        </div>
        <div class="order-details-calculation">
            <!-- <div class="total-header">Total Summary</div> -->
            <div class="calcuation-details" fxLayout="column" fxLayoutGap="3%">
                <!-- <div class="calculation">
                    <span class="order-details-text">Booking Fee</span>
                    <span class="order-details-text">₹ 0</span>
                </div>
                <div class="calculation">
                    <span class="order-details-text">Subtotal</span>
                    <span class="order-details-text">&#8377; {{experiencePackage[0]?.AMOUNT * totalCount}}</span>
                </div> -->
                <mat-divider class="divider"></mat-divider>
                <!-- <div class="calculation">
                    <span class="total">Total</span>
                    <span class="total">&#8377; {{experiencePackage[0]?.AMOUNT * totalCount}}</span>
                </div> -->
                <div class="calculation">
                    <span class="total">Payment Mode</span>
                    <span class="total">Pay Later</span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="bookingConfirm">
        <div fxLayout="column" fxLayoutGap="3%" fxLayoutAlign="center center" class="successfully-reserved-container">
            <img class="congratulation-img" src="assets/images/reserve-successfully.png" />
            <span class="congratulation-text">Congratulations! We've Received Your Booking. Someone From Our Team Will
                Get In Touch With You Soon.</span>
            <div>
                <button class="next-btn" (click)="doneButton()">Done</button>
            </div>
        </div>
    </div>
</mat-card>
<div *ngIf="orderDetails && !loading" class="reserve-btn-container">
    <button class="reserve-btn" (click)="reserveOrder()" type="submit">
        Reserve
    </button>
</div>
<app-footer></app-footer>