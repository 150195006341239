<app-header></app-header>
<div class="container">
  <div class="hero-banner width100" fxLayout="column" fxLayoutGap="1%">
    <h1 class="hero-banner-child-heading">
      Hotel Management Software
    </h1>
    <div>
      <h1 class="hero-banner-child-heading">The Modern Platform for Modern Hospitality</h1>
    </div>
    <div class="dashbord-image" *ngIf="mobile" [@imageTransition]="state">
      <img src="assets/mobile-images/home-dashboard.png">
    </div>
    <div>
      <span class="hero-banner-child-cont">GoFryDay brings you futuristic technology to improve experiences and simplify
        operations</span>
    </div>
    <div class="buttons">
      <button class="book-btn"
        onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
        Book a Demo
      </button>
      <button class="demo-link" (click)="selectTrial()">
        Talk to Us
        <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
      </button>
    </div>
    <div class="dashbord-image" *ngIf="!mobile" [@imageTransition]="state">
      <img class="home-dashboard" src="assets/images/home-dashboard.png">
    </div>
  </div>

  <div class="why-choose-container width100" fxLayout="column" fxLayoutAlign="center center">
    <div class="hero-banner-child-heading2">
      Why Choose GoFryday?
    </div>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="4%" class="why-choose">
        <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
          <img class="choose-image1" src="/assets/images/unified-img.png" alt="" />
          <div class="why-choose-content-head">
            UNIFIED PLATFORM
          </div>
          <div class="why-choose-text">Consolidate operations in one platform for real-time data visibility</div>
        </div>

        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/integrated-img.png" alt="" />
          <div class="why-choose-content-head">
            FULLY INTEGRATED
          </div>
          <div class="why-choose-text">One-stop toolset and an open API for easy connections</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/guest-img.png" alt="" />
          <div class="why-choose-content-head">
            GUEST-CENTRICITY
          </div>
          <div class="why-choose-text">Capture guest preferences through CRM</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/workflow-img.png" alt="" />
          <div class="why-choose-content-head">
            AUTOMATED WORKFLOWS
          </div>
          <div class="why-choose-text">Automate operations and guest communications</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/business-img.png" alt="" />
          <div class="why-choose-content-head">
            BUSINESS INTELLIGENCE
          </div>
          <div class="why-choose-text">Real-time insights for smarter business</div>
        </div>
        <div fxLayout="column" fxLayoutGap="1%" class="member-div">
          <img class="choose-image" src="/assets/images/cloud-img.png" alt="" />
          <div class="why-choose-content-head">
            SCALABLE & SECURE CLOUD INFRASTRUCTURE
          </div>
          <div class="why-choose-text">Secure cloud access, anytime, anywhere</div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
  <!--<div class="hero-banner-child" fxFlex="43%">
      <div class="hero-banner-child-cont">
        <h2 class="hero-banner-child-heading">Multiply
          <span class="hero-banner-child-heading2" [@letterAnimation]="animationState">
            {{ animatedWord }}
          </span>
        </h2>
        <h2 class="hero-banner-child-heading">Streamline <span class="hero-banner-child-heading2">Operations.</span>
        </h2>
        <h2 class="hero-banner-child-heading">Elevate <span class="hero-banner-child-heading2">Guest Experience.</span>
        </h2>
      </div>
      <div class="hero-banner-child-cont" style="width: 60%;">
        <span>AI-powered, cloud-based solution aimed at ensuring exponential success of your hotel.</span>
      </div>
      <div class="hero-banner-child-cont" fxLayout="row">
        <button class="book-btn"
          onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
          Book a Demo
        </button>
        <button class="demo-link" (click)="selectTrial()">
          Talk to Us
          <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
        </button>
      </div>
    </div>
    <div class="home-image" fxFlex="57%" *ngIf="imageState">
      <img src="assets/images/home-image.jpg">
    </div>-->
  <!-- </div> -->
  <!--<div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="10%" class="chart-div">
    <mat-card class="barChart-card" [ngClass]="cardState == true ? 'barChart-card' : 'invisible-card'">
      <canvas id="barChart" style="height: 275px;">{{ barchart }}</canvas>
    </mat-card>
    <mat-card class="pieChart-card" [ngClass]="cardState == true ? 'pieChart-card' : 'invisible-card'">
      <canvas id="pieChart">{{ piechart }}</canvas>
    </mat-card>
  </div> -->
  <!-- <div class="hero-banner width100" fxLayout="column" *ngIf="mobile">
    <div class="hero-banner-child" fxLayout="column">
      <div class="hero-banner-child-cont" fxLayout="column">
        <h2 class="hero-banner-child-heading">Multiply
          <span class="hero-banner-child-heading2" [@letterAnimation]="animationState">
            {{ animatedWord }}
          </span>
        </h2>
        <h2 class="hero-banner-child-heading">Streamline <span class="hero-banner-child-heading2">Operations.</span>
        </h2>
        <h2 class="hero-banner-child-heading">Elevate <span class="hero-banner-child-heading2">Guest Experience.</span>
        </h2>
      </div>
      <div class="hero-banner-child-cont">
        <img src="assets/images/home-image-mobile.jpg" style="width: 100%;">
      </div> -->
  <!-- <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="2%">
          <mat-card class="barChart-card">
            <canvas id="barChart" style="height: 275px;">{{ barchart }}</canvas>
          </mat-card>
          <mat-card class="pieChart-card">
            <canvas id="pieChart">{{ piechart }}</canvas>
          </mat-card>
        </div> -->
  <!-- <div class="hero-banner-child-cont">
        <span>AI-powered, cloud-based solution aimed at ensuring exponential success of your hotel.</span>
      </div>
      <div class="hero-banner-child-cont" fxLayout="column" fxLayoutAlign=" center">
        <button class="book-btn"
          onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">Book A
          Demo</button>
        <button class="demo-link" (click)="selectTrial()">
          Talk to Us
          <iconify-icon icon="material-symbols:arrow-right-alt" class="arrow-icon"></iconify-icon>
        </button>
      </div>
    </div>
  </div> -->
  <div class="powerhouse-container width100" fxLayout="column" fxLayoutAlign="center center">
    <div class="hospitality-heading">
      Managing operations for hotels internationally, GoFryday is a hospitality powerhouse
    </div>
    <div fxLayout="column" *ngIf="mobile" style="padding-top: 8%;">
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="center center">
            <img src="assets/images/bx_map.png">
            <div class="card-content">
              5+
            </div>
          </div>
          <div class="card-text-content">Countries</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="center center">
            <img src="assets/images/user-img.png">
            <div class="card-content">
              60+
            </div>
          </div>
          <div class="card-text-content">Customers</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="center center">
            <img src="assets/images/web-services-img.png">
            <div class="card-content">
              20k+
            </div>
          </div>
          <div class="card-text-content">Rooms Served/Month</div>
        </div>
      </mat-card>
    </div>
  </div>
  <div style="background: #F9F9F9;">
    <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="center" class="sub-card-row" *ngIf="!mobile">
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="start start">
            <img src="assets/images/bx_map.png">
            <div class="card-content">
              5+
            </div>
          </div>
          <div class="card-text-content">Countries</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="start start">
            <img src="assets/images/user-img.png">
            <div class="card-content">
              60+
            </div>
          </div>
          <div class="card-text-content">Customers</div>
        </div>
      </mat-card>
      <mat-card class="card-reaches">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
          <div fxLayout="row" fxLayoutGap="15%" fxLayoutAlign="start start">
            <img src="assets/images/web-services-img.png">
            <div class="card-content">
              20k+
            </div>
          </div>
          <div class="card-text-content">Rooms Served/Month</div>
        </div>
      </mat-card>
    </div>
    <div fxLayoutAlign="center">
      <video #mainVideo (click)="toggleVideo()" class="video1" controls [poster]="poster" [muted]="isMuted">
        <source src="assets/videos/unlock-new-possibilities.mp4" type="video/mp4" />
      </video>
    </div>
  </div>


  <div class="visual-container width100" fxLayout="column">
    <div class="hero-banner-child-heading2">
      Watch It Work: A Visual Guide to our products
    </div>
    <div class="visual-content" fxLayout="column" *ngIf="!mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
          <span class="visual-header">Guest App</span>
          <div class="visual-sub-text-contents">
            <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
            <span class="visual-sub-text">Easy-to-use Guest-Centric App</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2%">
          <div fxLayout="column" fxFlex="40%" fxLayoutGap="5%" fxLayoutAlign="start">
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Simple App, Happy Guests</div>
                <div class="info-desc">See your entire hotel in one place</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Boost Revenue with Upsells</div>
                <div class="info-desc">Maximize guest spending with app exclusives</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Real-time Feedback </div>
                <div class="info-desc">Listen, learn, improve with in-app surveys</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Live Service Tracking</div>
                <div class="info-desc">Instant visibility into service status</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Mobile Concierge</div>
                <div class="info-desc">Explore, dine, discover, and book - all in one app</div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
            <div fxFlex="60%" fxLayoutAlign=" center">
              <video #guestVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/guest-app.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="visual-content" fxLayout="column" *ngIf="mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
          <span class="visual-header">Guest App</span>
        </div>
        <span class="visual-sub-text">Easy-to-use Guest-Centric App</span>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
          <div fxLayoutAlign=" center">
            <video #guestVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
              <source src="assets/videos/guest-app.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start">
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Simple App, Happy Guests</div>
              <div class="info-desc">See your entire hotel in one place</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Boost Revenue with Upsells</div>
              <div class="info-desc">Maximize guest spending with app exclusives</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Real-time Feedback </div>
              <div class="info-desc">Listen, learn, improve with in-app surveys</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Live Service Tracking</div>
              <div class="info-desc">Instant visibility into service status</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Mobile Concierge</div>
              <div class="info-desc">Explore, dine, discover, and book - all in one app</div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="visual-content" fxLayout="column" *ngIf="!mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
          <span class="visual-header">CLIO – Admin Panel</span>
          <div class="visual-sub-text-contents">
            <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
            <span class="visual-sub-text">This intuitive AI – Powered admin panel is your ultimate Command Center</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2%">
          <div fxLayout="column" fxFlex="40%" fxLayoutGap="5%" fxLayoutAlign="start">
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Automated & Real-time Insights</div>
                <div class="info-desc">Proactive service through real-time guest insights</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Data-Driven Decisions</div>
                <div class="info-desc">Make informed decisions with actionable metrics</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Task Escalation & Role Assignment</div>
                <div class="info-desc">Assign roles and automate task escalation for urgent requests</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">360 degree tracking</div>
                <div class="info-desc">Track all guest needs and inventory</div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
            <div fxFlex="60%" fxLayoutAlign=" center">
              <video #clioVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="visual-content" fxLayout="column" *ngIf="mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
          <span class="visual-header">CLIO – Admin Panel</span>
        </div>
        <span class="visual-sub-text">This intuitive AI – Powered admin panel is your ultimate Command Center</span>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
          <div fxLayoutAlign=" center">
            <video #clioVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
              <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start">
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Automated & Real-time Insights</div>
              <div class="info-desc">Proactive service through real-time guest insights</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Data-Driven Decisions</div>
              <div class="info-desc">Make informed decisions with actionable metrics</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Task Escalation & Role Assignment</div>
              <div class="info-desc">Assign roles and automate task escalation for urgent requests</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">360 degree tracking</div>
              <div class="info-desc">Instant visibility Task Escalation & Role Assignmentinto service status</div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="visual-content" fxLayout="column" *ngIf="!mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
          <span class="visual-header">Task App</span>
          <div class="visual-sub-text-contents">
            <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
            <span class="visual-sub-text">Next-Level Task Management: Simplify Operations</span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2%">
          <div fxLayout="column" fxFlex="40%" fxLayoutGap="5%" fxLayoutAlign="start">
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Stay Compliant, Stay Ahead</div>
                <div class="info-desc">Automate compliance with SLAs and SOPs </div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Smarter Workflows</div>
                <div class="info-desc">Automate routine tasks, focus on guests</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Guest Requests at Fingertips</div>
                <div class="info-desc">Instant and comprehensive visibility into guest requests</div>
              </div>
            </mat-card>
            <mat-card class="info-card">
              <div fxLayout="column" fxLayoutGap="1%">
                <div class="info-head1">Celebrate Success</div>
                <div class="info-desc">Recognize, reward, and motivate staff</div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
            <div fxFlex="60%" fxLayoutAlign=" center">
              <video #taskVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/task-app.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="visual-content" fxLayout="column" *ngIf="mobile">
      <div fxLayout="column" class="visual-guide" fxLayoutGap="5%" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <iconify-icon icon="radix-icons:dot-filled" class="dot"></iconify-icon>
          <span class="visual-header">Task App</span>
        </div>
        <span class="visual-sub-text">Next-Level Task Management: Simplify Operations</span>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="4%">
          <div fxLayoutAlign=" center">
            <video #taskVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
              <source src="assets/videos/task-app.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start">
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Stay Compliant, Stay Ahead</div>
              <div class="info-desc">Automate compliance with SLAs and SOPs </div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Smarter Workflows</div>
              <div class="info-desc">Automate routine tasks, focus on guests</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Guest Requests at Fingertips</div>
              <div class="info-desc">Instant and comprehensive visibility into guest requests</div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="1%">
              <div class="info-head1">Celebrate Success</div>
              <div class="info-desc">Recognize, reward, and motivate staff</div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>

  <div class="knowledge-container width100" fxLayout="column">
    <div class="knowledge-header-contents">
      <div class="hero-banner-child-heading3">
        Empowering Hoteliers Through Knowledge
      </div>
      <div>
        <span class="hero-banner-child-text">We are honoured to partner with award-winning properties that select
          GoFryday
          for their needs. Our dedication to the hospitality industry and cutting-edge cloud hotel management software
          is
          unwavering. Follow our blog for insights on hotel management systems, hospitality trends, our latest clients,
          and more.</span>
      </div>
    </div>
    <div class="knowledge-row" fxLayout="row" fxLayoutAlign="center center">
      <mat-card class="knowledge-card" (click)='selectHicsa()'>
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/HICSA-image.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              HICSA and GoFryDay: A Perfect Correlation
            </div>
            <div class="knowledge-card-text">
              The recent HICSA (Hotel Investment Conference - South Asia) 2024 conference...
            </div>
          </div>
          <div class="readmore-text">Read More</div>
        </div>
      </mat-card>
      <mat-card class="knowledge-card" (click)='selectGuestCentricRevolution()'>
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/guest-image.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              The Guest-Centric Revolution
            </div>
            <div class="knowledge-card-text">
              Artificial Intelligence (AI) has transitioned from a futuristic concept to....
            </div>
          </div>
          <div class="readmore-text">Read More</div>
        </div>
      </mat-card>
      <mat-card class="knowledge-card" (click)='selectHotelCRM()'>
        <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
          <div class="knowledge-image">
            <img src="assets/images/CRM-image.png">
          </div>
          <div class="knowledge-content">
            <div class="knowledge-card-header">
              What Is A Hotel CRM & How It Maximizes Hotel Success?
            </div>
            <div class="knowledge-card-text">
              Discover how hotel CRM systems can enhance guest experience...
            </div>
          </div>
          <div class="readmore-text">Read More</div>
        </div>
      </mat-card>
    </div>
  </div>

  <div fxLayout="row" class="guest-experience-container width100" fxLayoutAlign="center center" *ngIf="!mobile">
    <div class="contact-image" fxFlex="50%">
      <img src="assets/images/guest-experience.png">
    </div>
    <div class="guest-experience-content" fxLayout="column" fxFlex="50%">
      <div class="guest-heading">
        Craft unforgettable guest experiences
      </div>
      <div class="guest-text">
        Are you prepared for a hospitality approach that's more efficient, adaptable, and centered around your guests?
      </div>
      <div style="margin-top: 3%;">
        <button class="book-btn"
          onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
          Book A Demo Today
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="guest-experience-container width100" fxLayoutAlign="center center" *ngIf="mobile">
    <div class="guest-experience-content">
      <div class="guest-heading">
        Craft unforgettable guest experiences
      </div>
      <div class="contact-image">
        <img src="assets/mobile-images/guest-experience.png">
      </div>
      <div class="guest-text">
        Are you prepared for a hospitality approach that's more efficient, adaptable, and centered around your guests?
      </div>
      <div style="margin-top: 3%; display: flex; justify-content: center;">
        <button class="book-btn"
          onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
          Book A Demo Today
        </button>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="!mobile">
    <div>
      <h3 class="faq-header">FAQ’S</h3>
    </div>
    <div fxLayout="column" class="faq-content">
      <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
          {{ faq.question }}
          <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
          {{ faq.answer }}
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="mobile">
    <div>
      <h3 class="faq-header">FAQ’S</h3>
    </div>
    <div fxLayout="column" class="faq-content">
      <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
        <div class="faq-question">
          {{ faq.question }}
          <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
        </div>
        <div class="faq-answer" *ngIf="faq.open">
          {{ faq.answer }}
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="info-container width100">
    <div fxLayout="column" class="info-container-content" *ngIf="!mobile">
      <div>
        <h2 class="info-heading1">Unlock New Possibilities With Us</h2>
      </div>
      <div fxLayout="row">
        <div fxLayout="column" fxFlex="40%" fxLayoutGap="5%" fxLayoutAlign="start" [@cardIn]="cardAnimationState">
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="4%">
              <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
                <div>
                  <img src="assets/images/benefit3.png">
                </div>
                <div class="info-head1">Multiply Revenue</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Intelligent recommendations for maximizing earning opportunities</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Centralized data insights for cross-selling and up-selling</div>
              </div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="4%">
              <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
                <div>
                  <img src="assets/images/benefit2.png">
                </div>
                <div class="info-head1">Streamline Operations</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Streamline operations with AI, prompt visibility and seamless tracking</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Reduce your operational costs and enhance overall efficiency</div>
              </div>
            </div>
          </mat-card>
          <mat-card class="info-card">
            <div fxLayout="column" fxLayoutGap="4%">
              <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
                <div>
                  <img src="assets/images/benefit1.png">
                </div>
                <div class="info-head1">Elevate Guest Experience</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Analyze guest interactions, preferences and feedback</div>
              </div>
              <div fxLayout="row" fxLayoutGap="5%">
                <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
                <div class="info-desc">Gather real-time insights for operational improvements</div>
              </div>
            </div>
          </mat-card>
        </div>
        <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="2%" [@videoIn]="videoAnimationState">
          <div fxFlex="60%" fxLayoutAlign=" center">
            <video #infoVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
              <source src="assets/videos/unlock-new-possibilities.mp4" type="video/mp4" />
            </video>
          </div>
          <div fxLayoutAlign="center">
            <button class="book-btn"
              onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
              Book a Demo
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="info-container-child" *ngIf="mobile">
      <div fxLayout="column" fxLayoutGap="5%">
        <div>
          <h2 class="info-heading1">Unlock New Possibilities With Us</h2>
        </div>
        <mat-card class="info-card">
          <div fxLayout="column" fxLayoutGap="4%">
            <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
              <div>
                <img src="assets/images/benefit3.png">
              </div>
              <div class="info-head1">Multiply Revenue</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Intelligent recommendations for maximizing earning opportunities</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Centralized data insights for cross-selling and up-selling</div>
            </div>
          </div>
        </mat-card>
        <mat-card class="info-card">
          <div fxLayout="column" fxLayoutGap="4%">
            <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
              <div>
                <img src="assets/images/benefit2.png">
              </div>
              <div class="info-head1">Streamline Operations</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Streamline operations with AI, prompt visibility and seamless tracking</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Reduce your operational costs and enhance overall efficiency</div>
            </div>
          </div>
        </mat-card>
        <mat-card class="info-card">
          <div fxLayout="column" fxLayoutGap="4%">
            <div fxLayout="row" fxLayoutGap="4%" fxLayoutAlign=" center">
              <div>
                <img src="assets/images/benefit1.png">
              </div>
              <div class="info-head1">Elevate Guest Experience</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Analyze guest interactions, preferences and feedback</div>
            </div>
            <div fxLayout="row" fxLayoutGap="5%">
              <div><iconify-icon icon="charm:circle-tick" class="tick-mark"></iconify-icon></div>
              <div class="info-desc">Gather real-time insights for operational improvements</div>
            </div>
          </div>
        </mat-card>
        <div fxLayout="column" fxLayoutAlign="center" style="margin-top: 8%;">
          <video #infoVideo (click)="toggleVideo()" class="video" controls [poster]="poster" style="width: 100%;"
            [muted]="isMuted">
            <source src="assets/videos/unlock-new-possibilities.mp4" type="video/mp4" />
          </video>
          <div fxLayoutAlign="center">
            <button class="book-btn"
              onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
              Book a Demo
            </button>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column">
      <div class="division1" *ngIf="!mobile">
      </div>
      <div class="division2" *ngIf="!mobile">
      </div>
      <div class="card-container">
        <div fxLayout="row" fxLayoutAlign="space-around center">
          <span class="info-head2">The <span class="info-head-text">GoFryDay</span> Triad</span>
        </div>
        <div fxLayout="column">
          <div #animationArea class="card-row" fxLayout="row" fxLayoutAlign="space-evenly center">
            <mat-card class="card" (click)="getVideo(video1)" (mouseenter)="getVideo(video1)"
              (mouseleave)="stopVideo(video1)" [@card1In]="card1AnimationState">
              <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start center">
                <div fxFlex="38%" class="heading info-head3">
                  <video #video1 oncontextmenu="return false;" class="video" controls [poster]="poster1"
                    style="width: 100%;" [muted]="'muted'">
                    <source src="assets/videos/guest-app.mp4" type="video/mp4" />
                  </video>
                </div>
                <div fxFlex="8%" class="card-header-body">
                  Guest App
                </div>
                <div class="card-body">
                  Optimize Every Stay, Maximize Every Pay. Elevate Up-selling, Empower Guests with Real-Time Request
                  Tracking and Feedback. Transform Legacy process flows.
                </div>
              </div>
            </mat-card>
            <mat-card class="card-middle" *ngIf="!mobile" (click)="getVideo(video2)" (mouseenter)="getVideo(video2)"
              (mouseleave)="stopVideo(video2)" [@card2In]="card2AnimationState">
              <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start center">
                <div fxFlex="28%" class="heading info-head3">
                  <video #video2 oncontextmenu="return false;" class="video" controls [poster]="poster2"
                    style="width: 100%;" [muted]="'muted'">
                    <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
                  </video>
                </div>
                <div fxFlex="8%" class="card-header-body">
                  Clio - AI Powered Admin Panel
                </div>
                <div class="card-body">
                  A centralized panel to view, manage, and optimize guest requests, reducing human resource dependency
                  and
                  leading to a significant decrease in operational costs.
                </div>
              </div>
            </mat-card>
            <mat-card class="card" *ngIf="mobile" (click)="getVideo(video2)" (mouseleave)="stopVideo(video2)">
              <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start center">
                <div fxFlex="28%" class="heading info-head3">
                  <video #video2 oncontextmenu="return false;" class="video" controls [poster]="poster2"
                    style="width: 100%;" [muted]="'muted'">
                    <source src="assets/videos/clio-admin-panel.mp4" type="video/mp4" />
                  </video>
                </div>
                <div fxFlex="15%" class="card-header-body">
                  Clio - AI Powered Admin Panel
                </div>
                <div class="card-body">
                  A centralized panel to view, manage, and optimize guest requests, reducing human resource dependency
                  and
                  leading to a significant decrease in operational costs.
                </div>
              </div>
            </mat-card>
            <mat-card class="card" (mouseenter)="getVideo(video3)" (click)="getVideo(video3)"
              (mouseleave)="stopVideo(video3)" [@card3In]="card3AnimationState">
              <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start center">
                <div fxFlex="28%" class="heading info-head3">
                  <video #video3 oncontextmenu="return false;" class="video" controls [poster]="poster3"
                    style="width: 100%;" [muted]="'muted'">
                    <source src="assets/videos/task-app.mp4" type="video/mp4" />
                  </video>
                </div>
                <div fxFlex="8%" class="card-header-body">
                  Task App
                </div>
                <div class="card-body">
                  Maximize efficiency with 360-degree tracking, ensuring real-time visibility, SOP adherence, and
                  streamlined SLA efficiency. Boost productivity through comprehensive monitoring for enhanced
                  performance.
                </div>
              </div>
            </mat-card>
          </div>
          <div *ngIf="!mobile" fxLayoutAlign="center" style="margin-top:100px;" (click)="getVideo(video2)"
            (mouseenter)="getVideo(video2)" (mouseleave)="stopVideo(video2)" [@card2In]="card2AnimationState">
            <button class="book-btn"
              onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
              Book a Demo
            </button>
          </div>
          <div *ngIf="mobile" fxLayoutAlign="center" style="margin-top: -70px;">
            <button class="book-btn"
              onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
              Book A Demo
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="hicsa-container width100" *ngIf="!mobile">
    <div fxLayout="row" fxLayoutGap="1%" class="hicsa-content" fxLayoutAlign="start start">
      <div fxLayout="column" fxFlex="35%" fxLayoutGap="2%">
        <h2 class="hicsa-heading">GoFryDay At HICSA’ 2024</h2>
        <span class="hicsa-text">
          Attending the recent HICSA (Hotel Investment Conference - South Asia) 2024 conference in Bengaluru, GoFryDay
          found themselves surrounded by hospitality leaders and innovators at this dynamic industry event.
        </span>
        <span style="margin-top: 5px;">
          <button class="book-btn" (click)="selectBlog()">
            Read More</button></span>
      </div>
      <div fxFlex="70%" class="hicsa-img">
        <img src="assets/images/Hicsa 2024.png">
        <span class="read-more">
          <button class="read-btn" (click)="selectBlog()">
            Read More</button></span>
      </div>
    </div>
    <div class="hicsa-images">
      <div fxLayout="row" fxLayoutGap="2%" fxLayoutAlign="space-between">
        <div class="hicsa-img1">
          <img src="assets/images/Hicsa-img1.png">
          <span class="read-more">
            <button class="read-btn" (click)="selectCorrelation()">
              Read More</button></span>
        </div>
        <div class="hicsa-img2">
          <img src="assets/images/Hicsa-img2.png">
          <span class="read-more">
            <button class="read-btn" (click)="selectCloud()">
              Read More</button></span>
        </div>
        <div class="hicsa-img3">
          <img src="assets/images/Hicsa-img3.png">
          <span class="read-more">
            <button class="read-btn" (click)="selectRevenue()">
              Read More</button></span>
        </div>
      </div>
    </div>
  </div>
  <div class="hicsa-container width100" *ngIf="mobile">
    <div fxLayout="column" fxLayoutAlign=" center">
      <h2 class="hicsa-heading">GoFryDay At HICSA’ 2024</h2>
      <div><img src="assets/mobile-images/Hicsa-2024.png"></div>
      <div><img src="assets/mobile-images/Hicsa-img1.png" (click)="selectCorrelation()"></div>
      <div><img src="assets/mobile-images/Hicsa-img2.png" (click)="selectCloud()"></div>
      <div><img src="assets/mobile-images/Hicsa-img3.png" (click)="selectRevenue()"></div>
      <div class="hicsa-mob-content" fxLayoutAlign=" center">
        <span class="hicsa-text">
          Attending the recent HICSA (Hotel Investment Conference - South Asia) 2024 conference in Bengaluru, GoFryDay
          found themselves surrounded by hospitality leaders and innovators at this dynamic industry event.
        </span>
      </div>
      <button class="book-btn" (click)="selectBlog()">
        Read More</button>
    </div>
  </div>
  <div class="headLineContainer width100" fxLayout="column">
    <div class="centered-text">
      <p class="single-text">Transform your guests' experience with our smart hospitality solutions</p>
      <button class="book-btn"
        onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
        Book a Demo</button>
    </div>
  </div>
  <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
    <div class="contact-image" fxLayout="column" fxFlex="50%">
      <div>
        <p class="single-line">We're always happy to hear from you.</p>
      </div>
      <div>
        <img src="assets/images/contact-us.png">
      </div>
    </div>
    <div class="contact-form" fxLayout="column" fxFlex="50%">
      <div class="form-heading">
        Contact Us
      </div>
      <div class="form">
        <form [formGroup]="contactUsForm">
          <div fxLayout="column" fxLayoutGap="5%">
            <div>
              <input type="text" class="text-box" formControlName="name" placeholder="Full Name" maxlength="200"
                autocomplete="off">
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                Required
              </mat-error>
            </div>
            <div>
              <input type="text" class="text-box-email" maxlength="200" formControlName="email" placeholder="Email"
                autocomplete="off">
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                Required
              </mat-error>
              <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                Please provide a valid Email Id
              </mat-error>
            </div>
            <div>
              <textarea class="text-area" formControlName="message" placeholder="Write message" maxlength="1500"
                autocomplete="off"></textarea>
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                Required
              </mat-error>
            </div>
            <div fxLayout="row" fxLayoutGap="2%">
              <input type="checkbox" class="check-box" formControlName="tnc" />
              <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                  (click)="viewPrivacyPolicy()">"Privacy
                  Policy"</a> and
                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                  Conditions"</a></span>
            </div>
          </div>
        </form>
      </div>
      <div style="text-align: center;">
        <button
          [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
          (click)="submitContactForm()"
          [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
          <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
    <div class="form-heading">
      Contact Us
    </div>
    <div>
      <p class="single-line">We're always happy to hear from you.</p>
    </div>
    <div style="text-align: center;">
      <img src="assets/images/contact-us.png">
    </div>
    <div class="contact-form" fxLayout="column">
      <div class="form">
        <form [formGroup]="contactUsForm">
          <div fxLayout="column" fxLayoutGap="5%">
            <div>
              <input type="text" class="text-box" formControlName="name" placeholder="Full Name" maxlength="200"
                autocomplete="off">
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                Required
              </mat-error>
            </div>
            <div>
              <input type="text" class="text-box-email" formControlName="email" placeholder="Email" autocomplete="off">
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                Required
              </mat-error>
              <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                Please provide a valid Email Id
              </mat-error>
            </div>
            <div>
              <textarea class="text-area" formControlName="message" placeholder="Write message" maxlength="1500"
                autocomplete="off"></textarea>
              <mat-error class="mat-error"
                *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                Required
              </mat-error>
            </div>
            <div fxLayout="row" fxLayoutGap="2%">
              <input type="checkbox" class="check-box" formControlName="tnc" />
              <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                  (click)="viewPrivacyPolicy()">"Privacy
                  Policy"</a> and
                <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                  Conditions"</a></span>
            </div>
          </div>
        </form>
      </div>
      <div style="text-align: center;">
        <button
          [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
          (click)="submitContactForm()"
          [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
          <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
        </button>
      </div>
    </div>
  </div> -->
</div>
<app-footer></app-footer>