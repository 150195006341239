<app-header></app-header>
<div class="container">
    <div class="task-app-container width100" fxLayout="row">
        <div class="task-app-child" fxLayout="column" fxLayoutGap="3%">
            <h1 class="task-app-child-heading">Task App: Hotel Task Management Software</h1>
            <div *ngIf="mobile" style="align-items: center;">
                <img src="/assets/mobile-images/Task-App-image.png" />
            </div>
            <div class="task-app-child-text">Introducing Task App, your all-in-one hotel task management software.
                Streamline operations, boost efficiency, and ensure exceptional guest service with clear task
                delegation, real-time updates, and seamless department collaboration.
            </div>
            <div>
                <button class="book-btn"
                    onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                    Book A Demo Today
                </button>
            </div>
        </div>
        <div class="image-container" *ngIf="!mobile">
            <img class="task-app-image" src="/assets/images/Task-App-image.png" alt="Clio" />
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="task-app-child-heading2">
            Advanced Capabilities of Our Task Automation System
        </div>
        <div class="task-app-child-text2">
            Enhance hotel operations with our intuitive Task automation software. Automate tasks like housekeeping,
            guest communication, and maintenance requests. Our interface boosts efficiency, freeing your team to deliver
            exceptional guest experiences
        </div>
        <div *ngIf="!mobile">
            <button class="book-btn"
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                Book A Demo Today
            </button>
        </div>
        <div fxLayout="column" class="why-choose-content-container">
            <div fxLayout="row" fxLayoutGap="4%" class="why-choose-content">
                <div class="member-div" fxLayout="column" fxLayoutGap="1%" fxLayoutAlign="center center">
                    <img class="choose-image" src="/assets/images/Task Assignment.png" alt="" />
                    <div class="why-choose-content-head">
                        Task Assignment and Tracking
                    </div>
                    <div class="why-choose-text">Assign and track tasks (housekeeping, requests, repairs) in real-time.
                        Streamline communication, set deadlines, and boost staff productivity
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/SLA SOP.png" alt="" />
                    <div class="why-choose-content-head">
                        SLA and SOP adherence
                    </div>
                    <div class="why-choose-text">Ensure SLA & SOP Compliance. Automate Reminders & Workflows. This
                        simplifies tasks, boosts efficiency, and delivers consistent, high-quality service for happier
                        guests</div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Staff Communication.png" alt="" />
                    <div class="why-choose-content-head">
                        Easy Staff Communication
                    </div>
                    <div class="why-choose-text">Our app keeps everyone informed with real-time messages, task updates,
                        and alerts. Less confusion, better teamwork
                    </div>
                </div>
                <div fxLayout="column" fxLayoutGap="1%" class="member-div">
                    <img class="choose-image" src="/assets/images/Guest Management.png" alt="" />
                    <div class="why-choose-content-head">
                        Guest Request Management
                    </div>
                    <div class="why-choose-text">Track requests live, assign tasks on the spot, and get automatic
                        updates. Ensure fast and efficient responses, enhancing guest satisfaction
                    </div>
                </div>
                <div *ngIf="mobile">
                    <button class="book-btn"
                        onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                        Book A Demo Today
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="info-container2 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="task-app-child-heading2">Benefits Of Our Hotel Task Management App</div>
        <div fxLayout="row" class="task-app-features-content">
            <div *ngIf="mobile" class="feature-image-container" fxLayout="column" fxLayoutAlign="center center">
                <div class="feature-image-circle">
                    <img class="feature-mobile-image" src="assets/mobile-images/Task-Management-image.png"
                        alt="Feature Image">
                </div>
            </div>
            <div fxLayout="column" class="left-side-points" fxLayoutGap="5%">
                <div fxLayout="column">
                    <div class="features-header">
                        Enhanced Efficiency
                    </div>
                    <div class="features-text">
                        By centralizing everything from assigning cleaning duties to coordinating guest requests, this
                        app streamline workflows, boosts communication, and ensures nothing gets missed
                    </div>
                </div>
                <div fxLayout="column">
                    <div class="features-header">
                        Improved Guest Satisfaction
                    </div>
                    <div class="features-text">
                        Guests love quick service. Our system speeds up response times, boosting satisfaction. This
                        translates to glowing online reviews and bringing you more loyal customers.
                    </div>
                </div>
                <div fxLayout="column">
                    <div class="features-header">
                        Real-Time Updates
                    </div>
                    <div class="features-text">
                        No More Miscommunication. Real-time updates keep staff on the same page, ensuring faster service
                        for happier guests
                    </div>
                </div>
                <div fxLayout="column">
                    <div class="features-header">
                        Increased Productivity
                    </div>
                    <div class="features-text">
                        See all tasks – cleaning, restocking, guest requests – in one central hub, prioritized and
                        visible to everyone. No more confusion or wasted time clarifying details
                    </div>
                </div>
            </div>
            <div *ngIf="!mobile" fxFlex="48%" class="feature-image-container" fxLayout="column"
                fxLayoutAlign="center center">
                <div class="feature-image-circle">
                    <img class="feature-mobile-image" src="assets/images/Task-Management-image.png" alt="Feature Image">
                </div>
            </div>
        </div>
        <div>
            <button class="book-btn"
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                Book A Demo Today
            </button>
        </div>
    </div>
    <div class="info-container1 width100" fxLayout="column" fxLayoutAlign="center center">
        <div class="task-app-child-heading3">
            How Our Hotel Task Management Software Works?
        </div>
        <div class="task-app-child-text2">
            With our Task app, teamwork flourishes as tasks are seamlessly assigned, tracked, and completed in
            real-time. Enhanced collaboration means smoother operations and happier guests, all managed effortlessly
            from one centralized platform.
        </div>
        <div>
            <video #taskVideo (click)="toggleVideo()" class="video" controls [poster]="poster" [muted]="isMuted">
                <source src="assets/videos/task-app.mp4" type="video/mp4" />
            </video>
        </div>
        <div>
            <button class="book-btn"
                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/gofryday_/30min'});return false;">
                Book A Demo Today
            </button>
        </div>
    </div>
    <div fxLayout="row" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="!mobile">
        <div>
            <h3 class="faq-header">FAQ’S</h3>
        </div>
        <div fxLayout="column" class="faq-content">
            <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
                <div class="faq-question">
                    {{ faq.question }}
                    <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
                </div>
                <div class="faq-answer" *ngIf="faq.open">
                    {{ faq.answer }}
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="column" class="faq-container width100" fxLayoutAlign="space-between" *ngIf="mobile">
        <div>
            <h3 class="faq-header">FAQ’S</h3>
        </div>
        <div fxLayout="column" class="faq-content">
            <div class="faq-item" *ngFor="let faq of faqs" (click)="toggleFAQ(faq)">
                <div class="faq-question">
                    {{ faq.question }}
                    <span class="faq-toggle">{{ faq.open ? '-' : '+' }}</span>
                </div>
                <div class="faq-answer" *ngIf="faq.open">
                    {{ faq.answer }}
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>