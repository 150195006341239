import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  updateTitle(title: string) {
    if (!!title) {
      this.title.setTitle(title);
    }
  }

  updateDescription(description: string) {
    if (!!description) {
      this.meta.updateTag({ name: 'description', content: description });
    }
  }

  updateOgTitle(ogtitle: string) {
    if (!!ogtitle) {
      this.meta.updateTag({ property: 'og:title', content: ogtitle });
    }
  }

  updateOgDescription(ogdescription: string) {
    if (!!ogdescription) {
      this.meta.updateTag({ property: 'og:description', content: ogdescription });
    }
  }

  // updateOgUrl(ogurl: string) {
  //   if (!!ogurl) {
  //     this.meta.updateTag({ property: 'og:url', content: ogurl });
  //   }
  // }

}