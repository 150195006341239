import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog2Component } from 'src/app/pages/blog2/blog2.component';
import { PopupComponent } from 'src/app/pages/popup/popup.component';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/shared/color-object';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  mobile: boolean = false;
  email = new FormControl('', Validators.pattern(environment.EMAIL_PATTERN));
  disabled: boolean = false;
  blogComponent: Blog2Component;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }


  viewLinkedIn() {
    window.open('https://www.linkedin.com/company/gofryday/', "_blank");
  }

  viewYoutube() {
    window.open('https://www.youtube.com/@GoFryday', "_blank");
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

  viewVision() {
    this.router.navigate(['/about-us'], { fragment: 'Vision' });
  }

  viewBelieveIn() {
    this.router.navigate(['/about-us'], { fragment: 'What-We-Believe-In' });
  }

  viewTeam() {
    this.router.navigate(['/about-us'], { fragment: 'Team' });
  }

  viewPricing() {
    this.router.navigate(['/plans'], { fragment: 'Pricing' });
  }

  viewTalktoSales() {
    this.router.navigate(['/plans'], { fragment: 'Talk-to-Sales' });
  }

  viewGuestApp() {
    this.router.navigateByUrl('/features/guest-app');
    window.scrollTo(0, 0);
  }

  viewClio() {
    this.router.navigateByUrl('/features/clio');
    window.scrollTo(0, 0);
  }

  viewTaskApp() {
    this.router.navigateByUrl('/features/task-app');
    window.scrollTo(0, 0);
  }

  viewHicsa() {
    this.router.navigate(['/blog/hicsa'], { fragment: 'hicsa-2024' });
  }

  viewAIHospitality() {
    this.router.navigate(['/blog/guest-centric-revolution'], { fragment: 'AI-In-Hospitality' });
  }

  viewHotelCRM() {
    this.router.navigateByUrl('blog/hotel-CRM');
    window.scrollTo(0, 0);
  }


  subscribe() {
    this.disabled = true;
    this.hotelService.isHideStickyContent(true);
    let obj = {
      "EMAIL": this.email.value
    }
    this.hotelService.subscribe(obj).subscribe({
      next: (data) => {
        this.disabled = false;
        this.email.setValue('');
        if (!!data?.object?.ID) {
          if (this.mobile == true) {
            const dialogRef = this.dialog.open(PopupComponent, {
              width: '80%',
              data: { name: 'subscribe', message: 'We have received your email. Stay tuned!' }
            });
          }
          else {
            const dialogRef = this.dialog.open(PopupComponent, {
              width: '46%',
              data: { name: 'subscribe', message: 'We have received your email. Stay tuned!' }
            });
            dialogRef.afterClosed().subscribe(() => {
              this.hotelService.isHideStickyContent(false);
            });
          }

        } else {
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        }
      },
      error: (e) => {
        this.disabled = false;
        this.email.setValue('');
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

}