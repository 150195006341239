import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-clio',
  templateUrl: './clio.component.html',
  styleUrls: ['./clio.component.css']
})
export class ClioComponent {
  @ViewChild("clioVideo", { static: false })
  clioVideo: ElementRef;
  mobile: boolean = false;
  isClioVideoIntoView: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  poster = '../../../assets/images/thumbnail.png';
  poster1 = '../../../assets/images/guest-app.png';
  isMuted: boolean = true;

  faqs = [
    {
      question: 'What types of hotels can benefit from your software?',
      answer: 'Our hospitality management software is designed to cater to a wide range of hotels, from small independent properties to large hotel chains.',
      open: false
    },
    {
      question: 'Can I integrate the software with my existing systems?',
      answer: 'Yes, our software is designed to integrate seamlessly with many popular systems used in the hospitality industry.',
      open: false
    },
    {
      question: 'What is hospitality management software?',
      answer: 'Hospitality management software is a comprehensive solution designed to streamline operations and enhance guest experiences in the hotel industry.',
      open: false
    },
    {
      question: 'How does hotel inventory management software work?',
      answer: 'Hotel inventory management software tracks and manages all your hotels resources, including rooms, amenities, and equipment.',
      open: false
    },
    {
      question: 'How secure is this software?',
      answer: 'Security is a top priority for us. Our software employs advanced security measures to protect your data.',
      open: false
    }
  ];

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.clioVideo) {
      this.isMuted = true;
      const rect = this.clioVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isClioVideoIntoView = topShown && bottomShown;
      if (this.isClioVideoIntoView == true) {
        this.clioVideo.nativeElement.play();
      } else {
        this.clioVideo.nativeElement.pause();
      }
    }
  }

  constructor() { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  toggleVideo() {
    this.isMuted = false;
  }

  getVideo(video) {
    video.play();
  }

  stopVideo(video) {
    video.pause();
  }

}
