import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { colorObj } from 'src/shared/color-object';

@Component({
  selector: 'app-experience-detail',
  templateUrl: './experience-detail.component.html',
  styleUrls: ['./experience-detail.component.css']
})
export class ExperienceDetailComponent {

  loading: boolean = true;
  experienceCities: any = [];
  experiencePackageCategory: any = [];
  experiencePackages: any = [];
  destination: string;
  destinationId: number;
  public bucketUrl = `${environment.BUCKET_URL}`;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private hotelService: HotelService,
    private router: Router,
    private notify: NotificationService
  ) { }

  ngOnInit() {
    this.checkScreenWidth();
    this.getCities();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getCities() {
    this.hotelService.getExperienceCity().subscribe({
      next: (data) => {
        this.experienceCities = data.object;
        this.setDetails();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  setDetails() {
    this.hotelService.setSelectedPlace(window.localStorage.getItem('SELECTED_CITY'));
    this.hotelService.getSelectedPlace().subscribe((val) => {
      for (let city of this.experienceCities) {
        if (city.ID == val) {
          this.destination = city.NAME;
          this.destinationId = city.ID;
        }
      }
    });
    this.getPackageCategory();
  }

  getPackageCategory() {
    this.hotelService.getExperiencePackageCategory(this.destinationId).subscribe({
      next: (data) => {
        this.experiencePackageCategory = data.object;
        this.getPackages();
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  getPackages() {
    this.hotelService.getExperiencePackages(this.destinationId).subscribe({
      next: (data) => {
        this.experiencePackages = data.object;
        this.loading = false;
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  backButton() {
    this.router.navigateByUrl('experiences');
    window.scrollTo(0, 0);
  }

  reservePackage(packageId) {
    localStorage.setItem("SELECTED_PACKAGE", packageId);
    this.router.navigateByUrl('reserve');
    window.scrollTo(0, 0);
  }

}