<div class="container">
    <div fxLayout="row" class="contact-us-container" *ngIf="!mobile">
        <div fxLayout="column">
            <div class="header">Contact Us</div>
            <div class="first-column">
                <div class="sales-team" fxLayout="column">
                    <div>
                        <img src="assets/images/sales.png">
                    </div>
                    <div class="talk-to-sale-text">Talk to our sales team</div>
                    <div class="text">We’ll help you find the right products and pricing for your business</div>
                </div>
            </div>
        </div>

        <div class="form-contents" fxFlex="40%">
            <div class="form-content">
                <div class="form-header">Please fill the form</div>
                <div class="form">
                    <form [formGroup]="contactUsForm">
                        <div fxLayout="column" fxLayoutGap="5%">
                            <div>
                                <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                    maxlength="200" autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div>
                                <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                    placeholder="Email" autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                    Required
                                </mat-error>
                                <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                    Please provide a valid Email Id
                                </mat-error>
                            </div>
                            <div>
                                <textarea class="text-area" formControlName="message" placeholder="Write message"
                                    maxlength="1500" autocomplete="off"></textarea>
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <!-- <div fxLayout="row" fxLayoutGap="2%">
                                <input type="checkbox" class="check-box" formControlName="tnc" />
                                <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                        (click)="viewPrivacyPolicy()">"Privacy
                                        Policy"</a> and
                                    <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                        Conditions"</a></span>
                            </div> -->
                        </div>
                    </form>
                </div>
                <div style="text-align: center;">
                    <button [ngClass]="this.contactUsForm.invalid ? 'invalid-btn' : 'submit-btn'"
                        (click)="submitContactForm()" [disabled]="this.contactUsForm.invalid || disabled">Submit
                        <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                    </button>
                </div>
            </div>
        </div>

        <div fxLayout="column" class="gofryday">
            <div>
                <img src="assets/images/Gofryday logo.png">
            </div>
            <div class="sub-text">
                All-in-One Solution for Growth, Efficiency, and Delighted Guests
            </div>
            <div fxLayout="row" fxLayoutGap="4%">
                <div fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center"
                        *ngFor="let GoFryDay of gofryday">
                        <span class="content">✓</span>
                        <div>
                            <span class="sub-text">{{ GoFryDay }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button mat-icon-button mat-dialog-close class="close-btn">
                <iconify-icon icon="carbon:close-outline"></iconify-icon>
            </button>
        </div>
    </div>

    <div fxLayout="column" class="contact-us-container" *ngIf="mobile">
        <div style="align-items: center; display: flex;place-content: center;">
            <img src="assets/images/Gofryday logo.png">
        </div>
        <div class="sales-team" fxLayout="column">
            <div>
                <img src="assets/images/sales.png">
            </div>
            <div class="talk-to-sale-text">Talk to our sales team</div>
            <div class="text">We’ll help you find the right products and pricing for your business</div>
        </div>
        <div class="form">
            <form [formGroup]="contactUsForm">
                <div fxLayout="column" fxLayoutGap="5%">
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                            maxlength="200" autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <div>
                        <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                            autocomplete="off">
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                            Required
                        </mat-error>
                        <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                            Please provide a valid Email Id
                        </mat-error>
                    </div>
                    <div>
                        <textarea class="text-area" formControlName="message" placeholder="Write message"
                            maxlength="1500" autocomplete="off"></textarea>
                        <mat-error class="mat-error"
                            *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                            Required
                        </mat-error>
                    </div>
                    <!-- <div fxLayout="row" fxLayoutGap="2%">
                        <input type="checkbox" class="check-box" formControlName="tnc" />
                        <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                (click)="viewPrivacyPolicy()">"Privacy
                                Policy"</a> and
                            <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                Conditions"</a></span>
                    </div> -->
                </div>
            </form>
        </div>
        <div style="text-align: center;">
            <button [ngClass]="this.contactUsForm.invalid ? 'invalid-btn' : 'submit-btn'" (click)="submitContactForm()"
                [disabled]="this.contactUsForm.invalid  || disabled">Submit
                <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
            </button>
        </div>
        <div>
            <button mat-icon-button mat-dialog-close class="close-btn">
                <iconify-icon icon="carbon:close-outline"></iconify-icon>
            </button>
        </div>
    </div>
</div>