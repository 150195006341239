import { Component, ElementRef, HostListener, Inject, Input, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { PopupComponent } from '../popup/popup.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/shared/color-object';
import { ActivatedRoute, Router, Event } from '@angular/router';
import { ContactUsComponent } from '../contact-us/contact-us.component';

interface Feature {
  id: number;
  image: string;
  service: string;
  head: string;
  description: string;
}

@Component({
  selector: 'app-features-page',
  templateUrl: './features-page.component.html',
  styleUrls: ['./features-page.component.css'],
  animations: [
    // trigger('vector1', [
    //   state('hide', style({ opacity: 0, transform: 'translateX(-100%)' })),
    //   state('show', style({ opacity: 1, transform: 'rotateY(180deg)' })),
    //   transition('hide => show', [
    //     style({ opacity: 0, transform: 'translateX(-100%)' }),
    //     animate('800ms ease-out', style({ opacity: 1, transform: 'rotateY(180deg)' })),
    //   ]),
    // ]),
    // trigger('feature1', [
    //   state('hide', style({ opacity: 0 })),
    //   state('show', style({ opacity: 1 })),
    //   transition('hide => show', [
    //     style({ opacity: 0 }),
    //     animate('500ms 1s  ease-out', style({ opacity: 1 })),
    //   ]),
    // ]),
    // trigger('video1', [
    //   state('hide', style({ opacity: 0 })),
    //   state('show', style({ opacity: 1 })),
    //   transition('hide => show', [
    //     style({ opacity: 0 }),
    //     animate('500ms 2s ease-out', style({ opacity: 1 })),
    //   ]),
    // ]),
    // trigger('vector2', [
    //   state('hide', style({ opacity: 0, transform: 'translateX(100%)' })),
    //   state('show', style({ opacity: 1, transform: 'rotateY(180deg) translateX(-45%)' })),
    //   transition('hide => show', [
    //     style({ opacity: 0, transform: 'translateX(100%)' }),
    //     animate('800ms ease-out', style({ opacity: 1, transform: 'rotateY(180deg) translateX(-45%)' })),
    //   ]),
    // ]),
    // trigger('feature2', [
    //   state('hide', style({ opacity: 0 })),
    //   state('show', style({ opacity: 1 })),
    //   transition('hide => show', [
    //     style({ opacity: 0 }),
    //     animate('500ms 1s ease-out', style({ opacity: 1 })),
    //   ]),
    // ]),
    // trigger('video2', [
    //   state('hide', style({ opacity: 0 })),
    //   state('show', style({ opacity: 1 })),
    //   transition('hide => show', [
    //     style({ opacity: 0 }),
    //     animate('500ms 2s ease-out', style({ opacity: 1 })),
    //   ]),
    // ]),
    // trigger('vector3', [
    //   state('hide', style({ opacity: 0, transform: 'translateX(-100%)' })),
    //   state('show', style({ opacity: 1, transform: 'rotateY(180deg)' })),
    //   transition('hide => show', [
    //     style({ opacity: 0, transform: 'translateX(-100%)' }),
    //     animate('800ms ease-out', style({ opacity: 1, transform: 'rotateY(180deg)' })),
    //   ]),
    // ]),
    // trigger('feature3', [
    //   state('hide', style({ opacity: 0 })),
    //   state('show', style({ opacity: 1 })),
    //   transition('hide => show', [
    //     style({ opacity: 0 }),
    //     animate('500ms 1s ease-out', style({ opacity: 1 })),
    //   ]),
    // ]),
    // trigger('video3', [
    //   state('hide', style({ opacity: 0 })),
    //   state('show', style({ opacity: 1 })),
    //   transition('hide => show', [
    //     style({ opacity: 0 }),
    //     animate('500ms 2s ease-out', style({ opacity: 1 })),
    //   ]),
    // ])
  ],
})

export class FeaturesPageComponent {

  // featureState1: string = 'hide';
  // featureState2: string = 'hide';
  // featureState3: string = 'hide';
  // guestText = "Guest App";
  // clioText = "CLIO";
  // taskText = "Task App";
  // @ViewChild('feature1', { static: false })
  // feature1: ElementRef;
  // @ViewChild('feature2', { static: false })
  // feature2: ElementRef;
  // @ViewChild('feature3', { static: false })
  // feature3: ElementRef;
  // @ViewChild('videoPlay1', { static: false })
  // videoPlay1: ElementRef;
  // @ViewChild('videoPlay2', { static: false })
  // videoPlay2: ElementRef;
  // @ViewChild('videoPlay3', { static: false })
  // videoPlay3: ElementRef;
  // poster1 = '../../../assets/images/guest-app.png';
  // poster2 = '../../../assets/images/clio-admin-panel.png';
  // poster3 = '../../../assets/images/task-app.png';
  // isMuted: boolean = true;
  // features: Feature[] = [
  //   {
  //     id: 1,
  //     image: 'assets/images/e-concierge.png',
  //     service: 'E- Concierge',
  //     head: 'Boost Transport Services',
  //     description: 'Easily manage and customize all transport packages. Enable direct guest bookings, update inventory in real-time, and notify the right staff instantly. Define SLAs and create an efficient Escalation Matrix for seamless operations'
  //   },
  //   {
  //     id: 2,
  //     image: 'assets/images/room-dining.png',
  //     service: 'In Room Dining',
  //     head: "Enhance Your Hotel's In Room Dining Experience",
  //     description: 'Showcase a variety of restaurants with their own special menus. Guests can conveniently place orders directly from the app, monitor real-time progress, and stay informed on food preparation. Collect valuable feedback for menu items and categorize them with tags and icons for efficient management.'
  //   },
  //   {
  //     id: 3,
  //     image: 'assets/images/house-keeping.png',
  //     service: 'House Keeping',
  //     head: 'Streamline Housekeeping Requests',
  //     description: "Guests can easily request housekeeping services via the app. Customize the requests menu to suit your hotel's preferences. Keep tabs on request statuses in real-time, automatically notifying the appropriate staff. Define SLAs for prompt and efficient service."
  //   },
  //   {
  //     id: 4,
  //     image: 'assets/images/spa.png',
  //     service: 'Spa',
  //     head: 'Revamp Your Spa',
  //     description: 'Empower guests to book spa therapies directly. Customize descriptions, inventory, and time slots effortlessly on the app. Gather real-time feedback and showcase a range of categories to elevate your spa experience.'
  //   },
  //   {
  //     id: 5,
  //     image: 'assets/images/conference-room.png',
  //     service: 'Conference Room',
  //     head: 'Conference Room Management',
  //     description: 'Capitalize on distressed inventory in real-time. Present all available conference rooms directly to potential customers. Manage inventory with customizable time slots.'
  //   },
  //   {
  //     id: 6,
  //     image: 'assets/images/package.png',
  //     service: 'Packages',
  //     head: 'Customized Activity Packages',
  //     description: 'Elevate guest experiences by offering customized activity packages. Craft inventory and flexible time slots for seamless bookings. Embrace real-time feedback to enhance guest satisfaction and revenue growth.'
  //   }
  // ]
  // selectedFeature = 1;
  // guestApp: string[] = [];
  // clio: string[] = [];
  // taskApp: string[] = [];
  mobile: boolean = false;
  // isFeature1IntoView: boolean;
  // isFeature2IntoView: boolean;
  // isFeature3IntoView: boolean;
  // contactUsForm: FormGroup;
  // emailPattern = environment.EMAIL_PATTERN;
  currentRoute: string;
  contactPopupSetTimeOut;
  disabled: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }
  // @ViewChild('featureContent', {}) public featureContent;
  // @HostListener('window:scroll', ['$event'])
  // isScrolledIntoView() {
  //   if (this.feature1 && window.innerWidth > 1250) {
  //     this.isMuted = true;
  //     const rect = this.feature1.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isFeature1IntoView = topShown && bottomShown;
  //     if (this.isFeature1IntoView == true) {
  //       this.featureState1 = 'show';
  //       this.videoPlay1.nativeElement.play();
  //     }
  //     else {
  //       this.videoPlay1.nativeElement.pause();
  //     }
  //   }
  //   if (this.feature1 && window.innerWidth < 1250) {
  //     this.isMuted = true;
  //     const rect = this.feature1.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isFeature1IntoView = topShown && bottomShown;
  //     if (this.isFeature1IntoView == true) {
  //       this.featureState1 = 'show';
  //     }
  //   }
  //   if (this.feature2 && window.innerWidth > 1250) {
  //     this.isMuted = true;
  //     const rect = this.feature2.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isFeature2IntoView = topShown && bottomShown;
  //     if (this.isFeature2IntoView == true) {
  //       this.featureState2 = 'show';
  //       this.videoPlay2.nativeElement.play();
  //     }
  //     else {
  //       this.videoPlay2.nativeElement.pause();
  //     }
  //   }
  //   if (this.feature2 && window.innerWidth < 1250) {
  //     this.isMuted = true;
  //     const rect = this.feature2.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isFeature2IntoView = topShown && bottomShown;
  //     if (this.isFeature2IntoView == true) {
  //       this.featureState2 = 'show';
  //     }
  //   }
  //   if (this.feature3 && window.innerWidth > 1250) {
  //     this.isMuted = true;
  //     const rect = this.feature3.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isFeature3IntoView = topShown && bottomShown;
  //     if (this.isFeature3IntoView == true) {
  //       this.featureState3 = 'show';
  //       this.videoPlay3.nativeElement.play();
  //     }
  //     else {
  //       this.videoPlay3.nativeElement.pause();
  //     }
  //   }
  //   if (this.feature3 && window.innerWidth < 1250) {
  //     this.isMuted = true;
  //     const rect = this.feature3.nativeElement.getBoundingClientRect();
  //     const topShown = rect.top >= 0;
  //     const bottomShown = rect.bottom <= window.innerHeight;
  //     this.isFeature3IntoView = topShown && bottomShown;
  //     if (this.isFeature3IntoView == true) {
  //       this.featureState3 = 'show';
  //     }
  //   }
  // }

  constructor(
    private hotelService: HotelService,
    private notify: NotificationService,
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<FeaturesPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.contactUsForm = this.fb.group({
    //   name: ['', [Validators.required, Validators.maxLength(200)]],
    //   email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    //   message: ['', Validators.required],
    //   tnc: ['', Validators.required]
    // });

    // this.router.events
    // .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    //   if (event.url.includes('/features')) {
    //     const extras: NavigationExtras = { state: { data: event.urlAfterRedirects } };
    //     this.route.queryParams.subscribe(params => {
    //       const targetSection = params['section'];
    //       if (targetSection) {
    //         setTimeout(() => {
    //           this.scrollToSection(targetSection);
    //         }, 1000);
    //       }
    //     });
    //   }
    // });
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.checkScreenWidth();
    let popupScreen = window.sessionStorage.getItem('featurePopup');
    if (!popupScreen) {
      this.showContactPopup();
    }
    // this.guestApp = ['Simplified Booking', 'Real time valuable feedback', 'Exclusive offers and promotions', 'Boost revenue by up-selling']
    // this.clio = ['Automated Real-time Monitoring of Guest Requests and Complaints', 'Intelligent recommendations', 'Data-Driven Insights and customizable dashboards', 'Task Escalation Procedures', 'Role Assignment', 'Additional Service Management of In-room dining, Housekeeping, Spa, Transportation, Curated Packages and Conference Room.', 'Efficient Inventory Management', '360 degree tracking and visibility']
    // this.taskApp = ['Prompt visibility into Managing guest request', 'Task assistance through Automation', 'Ensures SLA and SOP adherence', 'Recognition of outstanding performance']

  }

  ngOnDestroy() {
    clearTimeout(this.contactPopupSetTimeOut);
  }

  showContactPopup() {
    this.contactPopupSetTimeOut = setTimeout(() => {
      let popupScreen = window.sessionStorage.getItem('featurePopup');
      if (!popupScreen) {
        window.sessionStorage.setItem('featurePopup', 'true')
        const dialogRef = this.dialog.open(ContactUsComponent, {
          width: '80%',
          height: '81vh',
          position: { top: '14vh' },
          disableClose: true,
          autoFocus: false
        });
      }
    }, 6000);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

  }

  // private scrollToSection(sectionId: string) {
  //   const section = document.getElementById(sectionId);
  //   if (section) {
  //     const yOffset = -150;
  //     const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
  //     window.scrollTo({ top: y, behavior: 'smooth' });
  //   }
  // }

  // toggleVideo() {
  //   this.isMuted = false;
  // }

  // public featureScrollRight(): void {
  //   this.selectedFeature += 1;
  //   if (this.selectedFeature > this.features.length) {
  //     this.selectedFeature = 1;
  //   }
  //   this.featureContent.nativeElement.scrollTo({ left: (this.featureContent.nativeElement.scrollLeft + 250), behavior: 'smooth' });
  // }

  // public featureScrollLeft(): void {
  //   this.selectedFeature -= 1;
  //   if (this.selectedFeature < 1) {
  //     this.selectedFeature = this.features.length;
  //   }
  //   this.featureContent.nativeElement.scrollTo({ left: (this.featureContent.nativeElement.scrollLeft - 250), behavior: 'smooth' });
  // }

  // selectFeature(id) {
  //   this.selectedFeature = id;
  // }

  // submitContactForm() {
  //   this.disabled = true;
  //   let obj = {
  //     "NAME": this.contactUsForm.value.name,
  //     "EMAIL": this.contactUsForm.value.email,
  //     "MESSAGE": this.contactUsForm.value.message,
  //     "TNC": true
  //   }
  //   this.hotelService.contactUs(obj).subscribe({
  //     next: (data) => {
  //       this.disabled = false;
  //       if (!!data?.object?.ID) {
  //         const dialogRef = this.dialog.open(PopupComponent, {
  //           width: '50%',
  //           data: { name: 'contact-us', message: 'Thank you for reaching out to us! We will connect with you shortly.' }
  //         });
  //       } else {
  //         this.notify.showNotification(
  //           data.message,
  //           "top",
  //           (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
  //           data.status
  //         );
  //       }
  //       this.contactUsForm.reset();
  //     },
  //     error: (e) => {
  //       this.disabled = false;
  //       this.contactUsForm.reset();
  //       this.notify.showNotification(
  //         e.error.message,
  //         "top",
  //         (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
  //         e.error.status
  //       )
  //     }
  //   })
  // }

  // viewPrivacyPolicy() {
  //   window.open('privacy-policy', "_blank");
  // }

  // viewTermsConditions() {
  //   window.open('terms-conditions', "_blank");
  // }

  viewGuestApp() {
    this.dialogRef.close('guest-app');
  }

  viewCLIO() {
    this.dialogRef.close('clio');
  }

  viewTaskApp() {
    this.dialogRef.close('task-app');
  }

  viewHotelCRM() {
    this.dialogRef.close('hotel-crm');
  }

  viewRevolution() {
    this.dialogRef.close('revolution');
  }

}