import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-task-app',
  templateUrl: './task-app.component.html',
  styleUrls: ['./task-app.component.css']
})
export class TaskAppComponent {
  @ViewChild("taskVideo", { static: false })
  taskVideo: ElementRef;
  mobile: boolean = false;
  isTaskVideoIntoView: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  poster = '../../../assets/images/thumbnail.png';
  poster1 = '../../../assets/images/task-app.png';
  isMuted: boolean = true;

  faqs = [
    {
      question: 'What is hotel task management software?',
      answer: 'Hotel task management software streamlines operations by automating task assignment, tracking progress in real-time, and providing automated updates. It enhances efficiency, guest satisfaction, and overall hotel management by ensuring tasks are completed promptly and accurately.',
      open: false
    },
    {
      question: 'Is the software compatible with mobile devices?',
      answer: 'Yes',
      open: false
    },
    {
      question: 'How does the task automation feature work?',
      answer: 'Task automation in hotel management software assigns tasks automatically based on predefined criteria, tracks their progress in real-time, and notifies relevant staff members upon completion.',
      open: false
    },
    {
      question: 'Is the software customizable to our hotel’s specific needs?',
      answer: 'Yes',
      open: false
    },
    {
      question: 'How secure is the data within the software?',
      answer: 'The data within Task App is highly secure, employing encryption protocols, access controls, and regular audits to protect sensitive information such as guest details and operational data from unauthorized access or breaches.',
      open: false
    }
  ];

  toggleFAQ(faq: any) {
    faq.open = !faq.open;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.taskVideo) {
      this.isMuted = true;
      const rect = this.taskVideo.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isTaskVideoIntoView = topShown && bottomShown;
      if (this.isTaskVideoIntoView == true) {
        this.taskVideo.nativeElement.play();
      } else {
        this.taskVideo.nativeElement.pause();
      }
    }
  }

  constructor() { }
  ngOnInit() {
    this.checkScreenWidth();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  toggleVideo() {
    this.isMuted = false;
  }

  getVideo(video) {
    video.play();
  }

  stopVideo(video) {
    video.pause();
  }

}
