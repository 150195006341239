<app-header></app-header>
<div class="container">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div class="width100" fxLayout="column" fxLayoutGap="2%" *ngIf="!loading">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
            <div class="back-arrow" (click)="backButton()">
                <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
            </div>
            <div class="list-title">
                8 places in {{destination}}
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="3%" *ngFor="let category of experiencePackageCategory">
            <div class="semi-head">{{category.NAME}}</div>
            <ng-container *ngIf="!mobile">
                <ng-container *ngFor="let package of experiencePackages">
                    <ng-container *ngIf="category.ID == package.PACKAGE_CATEGORY.ID">
                        <div fxLayout="row" class="detail-container" fxLayoutGap="2%">
                            <div>
                                <img class="place-pic" src="{{bucketUrl + package.IMAGE}}">
                            </div>
                            <div fxLayout="column" style="width: 100%;" fxLayoutGap="2%">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div class="header">{{package.TITLE}}</div>
                                    <button mat-button class="reserve-btn"
                                        (click)="reservePackage(package.ID)">Reserve</button>
                                </div>
                                <div>
                                    <iconify-icon icon="material-symbols:star-rounded"
                                        *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                        [ngClass]="i < package.RATING ? 'star' : 'star-outline'"></iconify-icon>
                                </div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                                    <div class="detail-desc">{{package.DURATION}}</div>
                                    <iconify-icon icon="material-symbols:circle" class="circle"></iconify-icon>
                                    <div class="detail-desc">{{package.TYPE}}</div>
                                </div>
                                <div class="detail-desc">{{package.DESCRIPTION}}</div>
                                <div class="detail-price">&#8377;{{package.AMOUNT}}<span class="price-desc">per adult</span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <mat-divider style="width: 100%;" *ngIf="!mobile"></mat-divider>
            <ng-container *ngIf="mobile">
                <ng-container *ngFor="let package of experiencePackages">
                    <ng-container *ngIf="category.ID == package.PACKAGE_CATEGORY.ID">
                        <div fxLayout="column" class="detail-container" fxLayoutGap="3%">
                            <div fxLayout="row" fxLayoutGap="3%">
                                <div>
                                    <img class="place-pic" src="{{bucketUrl + package.IMAGE}}">
                                </div>
                                <div fxLayout="column" fxLayoutGap="2%">
                                    <div class="header">{{package.TITLE}}</div>
                                    <div>
                                        <iconify-icon icon="material-symbols:star-rounded"
                                            *ngFor="let star of [1, 2, 3, 4, 5]; let i = index"
                                            [ngClass]="i < package.RATING ? 'star' : 'star-outline'"></iconify-icon>
                                    </div>
                                    <div class="detail-desc">{{package.DURATION}}</div>
                                    <div class="detail-desc">{{package.TYPE}}</div>
                                </div>
                            </div>
                            <div class="mobile-desc">{{package.DESCRIPTION}}</div>
                            <div class="detail-price">&#8377;{{package.AMOUNT}}<span class="price-desc">per adult</span>
                            </div>
                            <button mat-button class="reserve-btn" (click)="reservePackage(package.ID)">Reserve</button>
                        </div>
                        <mat-divider style="width: 100%;"></mat-divider>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<app-footer></app-footer>