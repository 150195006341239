<app-header></app-header>
<div class="container">
    <div>
        <div fxLayout="column" class="blogHeader width100" fxLayoutGap="12px">
            <div class="blog-head">
                The Knowledge Hub
            </div>
            <div class="blog-description">
                We don't just inform, we empower. Bridging the Gap Between Knowledge and Action 
            </div>
        </div>
        <!-- <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="card" (click)="viewHicsa()">
                <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start">
                    <div fxFlex="40%" class="heading info-head3" *ngIf="!mobile">
                        <img src="assets/images/Hicsa.png">
                    </div>
                    <div fxFlex="40%" class="heading info-head3" *ngIf="mobile">
                        <img src="assets/mobile-images/Hicsa.png">
                    </div>
                    <div class="card-body">
                        <div class="card-header">
                            HICSA
                        </div>
                        <div class="card-sub-text">The recent HICSA (Hotel Investment Conference - South Asia) 2024
                            conference in Bengaluru was a vibrant hub for hospitality leaders and innovators. </div>
                        <div fxLayout="row" fxLayoutGap="5%">
                            <div class="card-sub-text">2 Mins Read</div>
                            <div class="arrow-right"><img src="assets/images/arrow-right.png"></div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewRevolution()">
                <div class="card-text" fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="start">
                    <div fxFlex="40%" class="heading info-head3" *ngIf="!mobile">
                        <img src="assets/images/Guest-centric.png">
                    </div>
                    <div fxFlex="40%" class="heading info-head3" *ngIf="mobile">
                        <img src="assets/mobile-images/Guest-centric.png">
                    </div>
                    <div class="card-body">
                        <div class="card-header">
                            THE GUEST-CENTRIC REVOLUTION
                        </div>
                        <div class="card-sub-text">Artificial Intelligence (AI) has transitioned from a futuristic
                            concept to a present-day reality, revolutionizing industries across the board.</div>
                        <div fxLayout="row" fxLayoutGap="5%">
                            <div class="card-sub-text">2 Mins Read</div>
                            <div class="arrow-right"><img src="assets/images/arrow-right.png"></div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div> -->

        <div class="card-row" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="card" (click)="viewHicsa()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/HICSA-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            HICSA and GoFryDay: A Perfect Correlation
                        </div>
                        <div class="card-text">
                            The recent HICSA (Hotel Investment Conference - South Asia) 2024 conference...
                        </div>
                    </div>
                    <div class="readmore-text">Read More</div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewRevolution()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/guest-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            The Guest-Centric Revolution
                        </div>
                        <div class="card-text">
                            Artificial Intelligence (AI) has transitioned from a futuristic concept to....
                        </div>
                    </div>
                    <div class="readmore-text">Read More</div>
                </div>
            </mat-card>
            <mat-card class="card" (click)="viewHotelCRM()">
                <div class="card-content-div" fxLayout="column" fxLayoutAlign="space-between stretch">
                    <div class="card-image">
                        <img src="assets/images/CRM-image.png">
                    </div>
                    <div class="card-content">
                        <div class="card-header">
                            What Is A Hotel CRM & How It Maximizes Hotel Success?
                        </div>
                        <div class="card-text">
                            Discover how hotel CRM systems can enhance guest experience...
                        </div>
                    </div>
                    <div class="readmore-text">Read More</div>
                </div>
            </mat-card>
        </div>
        <div fxLayout="row" class="contactContainer width100" *ngIf="!mobile">
            <div class="contact-image" fxLayout="column" fxFlex="50%">
                <div>
                    <p class="single-line">We're always happy to hear from you.</p>
                </div>
                <div>
                    <img src="assets/images/contact-us.png">
                </div>
            </div>
            <div class="contact-form" fxLayout="column" fxFlex="50%">
                <div class="form-heading">
                    Contact Us
                </div>
                <div class="form">
                    <form [formGroup]="contactUsForm">
                        <div fxLayout="column" fxLayoutGap="5%">
                            <div>
                                <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                    maxlength="200" autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div>
                                <input type="text" class="text-box-email" maxlength="200" formControlName="email"
                                    placeholder="Email" autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                    Required
                                </mat-error>
                                <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                    Please provide a valid Email Id
                                </mat-error>
                            </div>
                            <div>
                                <textarea class="text-area" formControlName="message" placeholder="Write message"
                                    maxlength="1500" autocomplete="off"></textarea>
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div fxLayout="row" fxLayoutGap="2%">
                                <input type="checkbox" class="check-box" formControlName="tnc" />
                                <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                        (click)="viewPrivacyPolicy()">"Privacy
                                        Policy"</a> and
                                    <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                        Conditions"</a></span>
                            </div>
                        </div>
                    </form>
                </div>
                <div style="text-align: center;">
                    <button
                        [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                        (click)="submitContactForm()"
                        [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                        <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                    </button>
                </div>
            </div>
        </div>
        <div fxLayout="column" class="contactContainer width100" *ngIf="mobile">
            <div class="form-heading">
                Contact Us
            </div>
            <div>
                <p class="single-line">We're always happy to hear from you.</p>
            </div>
            <div style="text-align: center;">
                <img src="assets/images/contact-us.png">
            </div>
            <div class="contact-form" fxLayout="column">
                <div class="form">
                    <form [formGroup]="contactUsForm">
                        <div fxLayout="column" fxLayoutGap="5%">
                            <div>
                                <input type="text" class="text-box" formControlName="name" placeholder="Full Name"
                                    maxlength="200" autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['name'].touched && contactUsForm.get('name')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div>
                                <input type="text" class="text-box-email" formControlName="email" placeholder="Email"
                                    autocomplete="off">
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['email'].touched && contactUsForm.get('email')?.hasError('required')">
                                    Required
                                </mat-error>
                                <mat-error class="mat-error" *ngIf="contactUsForm.get('email')?.hasError('pattern')">
                                    Please provide a valid Email Id
                                </mat-error>
                            </div>
                            <div>
                                <textarea class="text-area" formControlName="message" placeholder="Write message"
                                    maxlength="1500" autocomplete="off"></textarea>
                                <mat-error class="mat-error"
                                    *ngIf="contactUsForm.controls['message'].touched && contactUsForm.get('message')?.hasError('required')">
                                    Required
                                </mat-error>
                            </div>
                            <div fxLayout="row" fxLayoutGap="2%">
                                <input type="checkbox" class="check-box" formControlName="tnc" />
                                <span class="privacy-text">I have read and agree to the <a class="privacy-link"
                                        (click)="viewPrivacyPolicy()">"Privacy
                                        Policy"</a> and
                                    <a class="privacy-link" (click)="viewTermsConditions()">"Terms and
                                        Conditions"</a></span>
                            </div>
                        </div>
                    </form>
                </div>
                <div style="text-align: center;">
                    <button
                        [ngClass]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false ? 'invalid-btn' : 'submit-btn'"
                        (click)="submitContactForm()"
                        [disabled]="this.contactUsForm.invalid || contactUsForm.get('tnc')?.value == false || disabled">Submit
                        <i class="fa fa-spinner fa-spin" *ngIf="disabled"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>