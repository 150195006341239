import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HotelService } from 'src/app/services/hotel.service';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/shared/color-object';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-free-trial',
  templateUrl: './free-trial.component.html',
  styleUrls: ['./free-trial.component.css']
})

export class FreeTrialComponent implements OnInit {

  mobile: boolean = false;
  trial_details: boolean = false;
  trialEmailForm: FormGroup;
  country: FormControl = new FormControl();
  namePattern = /^[a-zA-Z]+$/;
  emailPattern = environment.EMAIL_PATTERN;
  mobilenopattern = /^[0-9]{1,50}$/;
  roomspattern = /^[1-9]\d*$/;
  disabled: boolean = false;
  private _onDestroy = new Subject<void>();
  countryList: any = [];
  countryCode: any = '';
  selectedCountry: any;
  filterList: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private hotelService: HotelService,
    private notify: NotificationService
  ) {
    this.trialEmailForm = this.fb.group({
      hotelName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      countryName: [''],
      phoneCode: [''],
      phoneNumber: ['', Validators.pattern(this.mobilenopattern)],
      tnc: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    let countryListArray = window.localStorage.getItem('COUNTRY');
    this.countryList = countryListArray;
    this.countryList = JSON.parse(this.countryList);
    this.filterList = this.countryList;
    this.checkScreenWidth();
  }

  filterCountry() {
    let search = this.country.value;
    this.filterList = this.countryList.filter(country =>
      country.NAME.toLowerCase().indexOf(search.toLowerCase()) !== -1 || country.CODE.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  ngAfterViewInit() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
    this.country.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountry();
      });
  }

  changeCountry(country) {
    for (let coun of this.countryList) {
      if (coun.ID == country) {
        this.countryCode = '+' + coun.CODE;
        this.selectedCountry = coun.NAME;
      }
    }
    this.trialEmailForm.controls["phoneNumber"].addValidators([Validators.required]);
    this.trialEmailForm.controls["phoneNumber"].updateValueAndValidity();
  }

  getInput(event) {
    if (event.target.value.length != 0) {
      this.trialEmailForm.controls["phoneCode"].setValidators([Validators.required]);
    } else {
      this.trialEmailForm.controls["phoneCode"].clearValidators();
    }
    this.trialEmailForm.controls["phoneCode"].updateValueAndValidity();
  }

  startFreeTrial() {
    this.disabled = true;
    let obj = {
      "HOTEL_NAME": this.trialEmailForm.value.hotelName,
      "EMAIL": this.trialEmailForm.value.email,
      "COUNTRY_NAME": this.trialEmailForm.value.countryName,
      "COUNTRY": this.trialEmailForm.value.phoneCode,
      "PHONE_NUMBER": this.trialEmailForm.value.phoneNumber,
      "TNC": true
    }
    this.hotelService.freeTrial(obj).subscribe({
      next: (data) => {
        this.disabled = false;
        if (this.mobile == true) {
          const dialogRef = this.dialog.open(PopupComponent, {
            width: '80%',
            data: { name: 'free-trial', message: "Congratulations! You've Successfully Registered For The Free Trial. Someone From Our Team Will Get In Touch With You Within 24 Hours!" },
            disableClose: true
          });
        }
        else {
          const dialogRef = this.dialog.open(PopupComponent, {
            width: '46%',
            data: { name: 'free-trial', message: "Congratulations! You've Successfully Registered For The Free Trial. Someone From Our Team Will Get In Touch With You Within 24 Hours!" },
            disableClose: true
          });
        }
        this.trial_details = true;
        this.trialEmailForm.reset();
        this.disabled = true;
      },
      error: (e) => {
        this.disabled = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  viewPrivacyPolicy() {
    window.open('privacy-policy', "_blank");
  }

  viewTermsConditions() {
    window.open('terms-conditions', "_blank");
  }

}